import React, { useCallback, useEffect, useState } from "react";
import { FormText, Input } from "reactstrap";
import { projectsConstants } from "../../../../../../_constants/projects.constants";

function isAnalogSensor(terminalDeviceSensor) {
  return terminalDeviceSensor?.sensorId?.physicalCommunicationType === projectsConstants.global.sensors.phys.analog
}

function isMilliAmpsSensor(terminalDeviceSensor) {
  return (
    isAnalogSensor(terminalDeviceSensor) && 
    terminalDeviceSensor?.sensorId?.name === projectsConstants.global.sensors.name.generic_milli_amps
  );
}

function isCudSensor(terminalDeviceSensor){
  return terminalDeviceSensor?.sensorId?.physicalCommunicationType === projectsConstants.global.sensors.phys.cuds
}

function isDigitalSensor(terminalDeviceSensor){
  return terminalDeviceSensor?.sensorId?.physicalCommunicationType === projectsConstants.global.sensors.phys.digital
}

function isCudOrDigitalSensor(terminalDeviceSensor){
  return isCudSensor(terminalDeviceSensor) ||isDigitalSensor(terminalDeviceSensor)
}

function getMinReferencePointValue(terminalDeviceSensor) {
  if (isMilliAmpsSensor(terminalDeviceSensor)) {
    return 4;
  }
  return 0;
}

function getMaxReferencePointValue(terminalDeviceSensor) {
  if(isAnalogSensor(terminalDeviceSensor)){
    if (isMilliAmpsSensor(terminalDeviceSensor)) {
      return 20;
    }
    return 10;
  }

  return 4294967295
}

function getSensorUnit(terminalDeviceSensor) {
  if(isCudOrDigitalSensor(terminalDeviceSensor)){
    return " pulsos"
  }
  if (isMilliAmpsSensor(terminalDeviceSensor)) {
    return "mA";
  }

  return "V";
}

function isInvalidSensorMeasurementUnit(targetMeasurement) {
  return (
    targetMeasurement?.measurementUnit === undefined ||
    targetMeasurement?.measurementUnit === ""
  );
}

function isValidSensorMeasurementUnit(targetMeasurement) {
  return !isInvalidSensorMeasurementUnit(targetMeasurement);
}

function isInvalidReferencePoint(terminalDeviceSensor, targetMeasurement) {
  if (
    targetMeasurement?.calibrationRawValue === "" ||
    isNaN(targetMeasurement?.calibrationRawValue)
  ) {
    return true;
  }

  const calibrationRawValue = parseInt(targetMeasurement?.calibrationRawValue) + (isMilliAmpsSensor(terminalDeviceSensor) ? 4 : 0);
  return (
    calibrationRawValue < getMinReferencePointValue(terminalDeviceSensor) ||
    calibrationRawValue > getMaxReferencePointValue(terminalDeviceSensor)
  );
}

function isValidReferencePoint(terminalDeviceSensor, targetMeasurement) {
  return !isInvalidReferencePoint(terminalDeviceSensor, targetMeasurement);
}

function isInvalidCalibrationUnitValue(targetMeasurement) {
  return (
    targetMeasurement?.calibrationUnitValue === "" ||
    isNaN(targetMeasurement?.calibrationUnitValue) || 
    targetMeasurement?.calibrationUnitValue <= 0
  );
}

function isValidCalibrationUnitValue(targetMeasurement) {
  return !isInvalidCalibrationUnitValue(targetMeasurement);
}

export function isValidMeasurement(terminalDeviceSensor, targetMeasurement) {
  return (
    isValidSensorMeasurementUnit(targetMeasurement) &&
    isValidReferencePoint(terminalDeviceSensor, targetMeasurement) &&
    isValidCalibrationUnitValue(targetMeasurement)
  );
}

export const SensorGenericMeasurementConfiguration = (props) => {
  const {
    terminalDeviceSensor,
    onTerminalDeviceSensorMeasurementChange,
    associatedOutputs
  } = props;

  const [measurement, setMeasurement] = useState(
    terminalDeviceSensor?.measurement
  );

  useEffect(() => {
    setMeasurement(terminalDeviceSensor?.isUpdate ? {} : terminalDeviceSensor?.measurement);
  }, [terminalDeviceSensor]);

  const getRangeReferencePoints = useCallback(() => {
    return `${getMinReferencePointValue(terminalDeviceSensor)}${getSensorUnit(
      terminalDeviceSensor
    )}-${getMaxReferencePointValue(terminalDeviceSensor)}${getSensorUnit(
      terminalDeviceSensor
    )}`;
  }, [terminalDeviceSensor]);

  const getReferencePointTitle = useCallback(() => {
    if(isCudOrDigitalSensor(terminalDeviceSensor)){
      return `Pulsos de referencia:`
    }

    return `Punto de referencia debe estar entre (${getRangeReferencePoints()}):`
  }, [getRangeReferencePoints, terminalDeviceSensor])

  const onMeasurementChange = useCallback(
    (measurement) => {
      setMeasurement((current) => {
        const updatedMeasurement = {
          ...current,
          ...measurement,
        };

        onTerminalDeviceSensorMeasurementChange(updatedMeasurement);
        return updatedMeasurement;
      });
    },
    [onTerminalDeviceSensorMeasurementChange]
  );

  const onUnitChange = useCallback(
    (measurementUnit) => {
      onMeasurementChange({
        measurementUnit: measurementUnit,
        sensorUnit: getSensorUnit(terminalDeviceSensor),
      });
    },
    [onMeasurementChange, terminalDeviceSensor]
  );

  const onCalibrationRawValueChange = useCallback(
    (calibrationRawValue) => {
      onMeasurementChange({
        calibrationRawValue: parseInt(calibrationRawValue) - (isMilliAmpsSensor(terminalDeviceSensor) ?  4 : 0)
      });
    },
    [onMeasurementChange, terminalDeviceSensor]
  );

  const onMaxValueChange = useCallback(
    (calibrationUnitValue) => {
      onMeasurementChange({
        calibrationUnitValue: parseFloat(calibrationUnitValue),
        equation: isMilliAmpsSensor(terminalDeviceSensor)
          ? `(({sensorValue}-4)*${calibrationUnitValue})/${measurement?.calibrationRawValue}`
          : `({sensorValue}*${calibrationUnitValue})/${measurement?.calibrationRawValue}`,
      });
    },
    [measurement?.calibrationRawValue, onMeasurementChange, terminalDeviceSensor]
  );

  const getCalibrationRawValueWithUserCorrection = useCallback(() => {
    if(measurement && isMilliAmpsSensor(terminalDeviceSensor)){
      return measurement?.calibrationRawValue + 4
    }

    return measurement?.calibrationRawValue
  }, [measurement, terminalDeviceSensor])

  return (
    <>
      <div className="Title_Descripction">Unidad medida por el sensor:</div>
      <Input
        type="text"
        min="1"
        placeholder={"Unidad del sensor..."}
        className="InputForm descriptionInput"
        onChange={(e) => onUnitChange(e.target.value)}
        value={measurement?.measurementUnit || ""}
        invalid={isInvalidSensorMeasurementUnit(measurement)}
        disabled={associatedOutputs?.length > 0}
      />
      {associatedOutputs?.length > 0 && <FormText>No se puede modificar, tiene salidas asociadas.</FormText>}

      <div className="Title_Descripction">
        {getReferencePointTitle()}
      </div>
      <Input
        type="number"
        min="0"
        placeholder={"Referencia..."}
        className="InputForm descriptionInput"
        onChange={(e) => onCalibrationRawValueChange(e.target.value)}
        value={getCalibrationRawValueWithUserCorrection() || ""}
        invalid={isInvalidReferencePoint(terminalDeviceSensor, measurement)}
        disabled={isInvalidSensorMeasurementUnit(measurement)}
      />

      <div className="Title_Descripction">
        Valor ({measurement?.measurementUnit}) para (
        {getCalibrationRawValueWithUserCorrection()}
        {getSensorUnit(terminalDeviceSensor)}):
      </div>
      <Input
        type="number"
        min="0"
        placeholder={"Valor en la unidad medida..."}
        className="InputForm descriptionInput"
        onChange={(e) => onMaxValueChange(e.target.value)}
        value={isNaN(measurement?.calibrationUnitValue) || measurement?.calibrationUnitValue < 0 ? "" : measurement?.calibrationUnitValue}
        invalid={isInvalidCalibrationUnitValue(measurement)}
        disabled={isInvalidReferencePoint(terminalDeviceSensor, measurement)}
      />
    </>
  );
};
