import React, { useCallback } from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { CudDigitalSensorCard } from "./CardSensor/CudDigitalSensorCard";

export const TerminalDeviceGenericDigitalSensorView = (props) => {

  const {
    terminalDevice,
    onSensorClick
  } = props

  const getSensorComponents = useCallback(physicalCommunicationType => {
    if (terminalDevice?.sensors instanceof Array) {
      let cardSensors = terminalDevice.sensors
        .sort( (a, b) => a?.sensorIndex - b?.sensorIndex)
        .filter(
          (sensor) =>
            sensor?.active &&
            sensor?.sensorId?.physicalCommunicationType === physicalCommunicationType
        )
        .flatMap(sensor => sensor.measures.map(measure => ({...sensor, measure})))
        .filter(wrapSensorMeasure => !!(wrapSensorMeasure.measure))
        .map((wrapSensorMeasure, index) => {
          return (
            <CudDigitalSensorCard key={index} terminalDevice={terminalDevice} sensor={wrapSensorMeasure} onSensorClick={onSensorClick}/>
          );
        });

      return cardSensors
    }
    return <>No hay sensores analógicos configurados.</>;
  }, [onSensorClick, terminalDevice]);

  const getComponents = useCallback(() => {
    return <>
        <>{getSensorComponents( projectsConstants.global.sensors.phys.cuds)}</>
        <>{getSensorComponents( projectsConstants.global.sensors.phys.digital)}</>
    </>
  }, [getSensorComponents])

  return <>
   <div className="sensorsCardFather">{getComponents()}</div>
  </>;
};
