import React from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { TerminalDeviceSensorGenericView } from "./TerminalDeviceSensorGenericView";

export const TerminalDeviceRS485SensorView = (props) => {

  const {
    terminalDevice,
    onRS485SensorClick
  } = props

  return <TerminalDeviceSensorGenericView 
    terminalDevice={terminalDevice} 
    physicalCommunicationType={projectsConstants.global.sensors.phys.RS485}
    onSensorClick={onRS485SensorClick}
    />
};