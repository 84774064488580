import { projectsConstants } from "../../../../../_constants/projects.constants";
import {
  isTerminalDeviceMMSValidFor,
  isTerminalDeviceSIOValidFor,
} from "../../VersionChecker";

// CALIBRATION
const isTerminalDeviceMMSValidForOutputCalibration = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_OUTPUTS_CALIBRATION,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_OUTPUTS_CALIBRATION
  );

const isTerminalDeviceSIOValidForOutputCalibration = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_OUTPUTS_CALIBRATION,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_OUTPUTS_CALIBRATION
  );

export const isTerminalDeviceValidForOutputCalibration = (terminalDevice) => {
  return (
    isTerminalDeviceMMSValidForOutputCalibration(terminalDevice) ||
    isTerminalDeviceSIOValidForOutputCalibration(terminalDevice)
  );
};

// ADVANCED ACTOUTPUT
const isTerminalDeviceMMSValidForAdvancedActoutput = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_ADVANCED_ACTOUTPUT,
    projectsConstants.master_outputs.versions.MMS_HW3_MIN_ADVANCED_ACTOUTPUT
  );

const isTerminalDeviceSIOValidForAdvancedActoutput = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_ADVANCED_ACTOUTPUT,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_ADVANCED_ACTOUTPUT
  );

export const isValidForAdvancedActoutput = (terminalDevice) =>
  isTerminalDeviceMMSValidForAdvancedActoutput(terminalDevice) ||
  isTerminalDeviceSIOValidForAdvancedActoutput(terminalDevice);



// Digital sensor associate

const isTerminalDeviceMMSValidForOutputWithAssociateDigitalSensor = (terminalDevice) =>
  isTerminalDeviceMMSValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_DIGITAL_SENSOR_ASSOCIATE,
    projectsConstants.master_outputs.versions.MMS_HW1_MIN_DIGITAL_SENSOR_ASSOCIATE,
    projectsConstants.master_outputs.versions.MMS_HW5_MIN_DIGITAL_SENSOR_ASSOCIATE,
  );

const isTerminalDeviceSIOValidForOutputWithAssociateDigitalSensor = (terminalDevice) =>
  isTerminalDeviceSIOValidFor(
    terminalDevice,
    projectsConstants.master_outputs.versions.SIO_HW1_MIN_DIGITAL_SENSOR_ASSOCIATE,
    projectsConstants.master_outputs.versions.SIO_HW3_MIN_DIGITAL_SENSOR_ASSOCIATE
  );

export const isTerminalDeviceValidForOutputWithAssociateDigitalSensor = terminalDevice => 
  isTerminalDeviceMMSValidForOutputWithAssociateDigitalSensor(terminalDevice) ||
  isTerminalDeviceSIOValidForOutputWithAssociateDigitalSensor(terminalDevice) ||
  (terminalDevice.device.name === projectsConstants.global.devices.LSC_V4 && parseInt(terminalDevice.mainCurrentVersion) >= projectsConstants.lora_outputs.versions.LSC_V4_HW1_MIN_DIGITAL_SENSOR_ASSOCIATE)


export const isTerminalDeviceValidForOutputVoltageSelector = terminalDevice => {

  if(terminalDevice.device.name === projectsConstants.global.devices.PKT7
    || terminalDevice.device.name === projectsConstants.global.devices.LSC_V1
    || terminalDevice.device.name === projectsConstants.global.devices.LSC_V4){
      
      return false
    }

  if(terminalDevice.device.name === projectsConstants.global.devices.MMS && parseInt(terminalDevice.hardwareVersion ?? '0') >= 3){
    return true
  }

  return true
}

export const isTerminalDeviceValidForOutputTypeConfiguration = terminalDevice => {

  if(terminalDevice.device.name === projectsConstants.global.devices.PKT7
    || terminalDevice.device.name === projectsConstants.global.devices.LSC_V1
    || terminalDevice.device.name === projectsConstants.global.devices.LSC_V4){
      
      return false
    }
    
  return true
}
   