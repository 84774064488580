import React from "react";
import { TerminalDeviceSensorActiveState } from "./TerminalDeviceSensorActiveState";
import { TerminalDeviceSensorReportInterval } from "./TerminalDeviceSensorReportInterval";
import { TerminalDeviceSensorNotifiedState } from "./TerminalDeviceSensorNotifiedState";

export const TerminalDeviceSensorCommonConfiguration = (props) => {
  const { 
    sensor,
    onChangeActiveState,
    onReportIntervalSelected,
    allowSpecificReportTime = true,
    allowDisable = true,
    ignoreNotified = false
   } = props;

  return (
    <>
      {!ignoreNotified && <TerminalDeviceSensorNotifiedState sensor={sensor} />}
      {allowDisable && <TerminalDeviceSensorActiveState
        sensor={sensor}
        onChange={onChangeActiveState}
      />
      }
      {allowSpecificReportTime && sensor?.active && (
        <TerminalDeviceSensorReportInterval
          sensor={sensor}
          onReportOptionClick={onReportIntervalSelected}
        />
      )}
    </>
  );
};
