import React, { useCallback, useEffect } from "react";
import _ from "lodash";
import { projectsConstants } from "../../../_constants/projects.constants";
import { GetsensorvalueMQTT } from "../MQTT/GetsensorvalueMQTT";
import { ReportSensorsMQTT } from "../MQTT/ReportSensorsMQTT";
import { SetTriggersMQTT } from "../MQTT/triggers/SetTriggersMQTT";
import { DeleteTriggersMQTT } from "../MQTT/triggers/DeleteTriggersMQTT";
import { SensorsConfigMQTT } from "../MQTT/SensorsConfigMQTT";
import { isTerminalDeviceValidForReportSensorValues } from "./Fichas/SensorsConfiguration/ReportSensorValuesChecker";
import { useApp } from "../../../context/app-context";
import { JoinedReportMQTT } from "../MQTT/JoinedReportMQTT";

const CUD_MASK = 0b01
const DIGITAL_MASK = 0b1001

function getPhyisicalCommunicationTypeFromBitConfig(bitConfig){
  if(DIGITAL_MASK & bitConfig){
    return projectsConstants.global.sensors.phys.digital
  }
  
  if(CUD_MASK & bitConfig){
    return projectsConstants.global.sensors.phys.cuds
  }

  return projectsConstants.global.sensors.phys.analog
}

export const TerminalDeviceSensorStatesHandler = (props) => {
  const { terminalDevice } = props;
  const {
    updateTerminalDevice,
  } = useApp();
  

  const processGetsensorvalueMsg = useCallback(
    (messageId, message) => {
      const data = message?.data;
      if (
        data &&
        data?.target_id === terminalDevice.id &&
        terminalDevice?.sensors instanceof Array
      ) {
        const sensorIndex = data?.id - 1;
        const sensorValue = data?.voltage_value;

        if (sensorIndex >= 0) {
          // Buscamos el sensor con el mismo indice
          terminalDevice.sensors
            .filter(
              (sensor) =>
                sensor?.sensorIndex === sensorIndex &&
                sensor?.sensorId?.physicalCommunicationType ===
                  projectsConstants.global.sensors.phys.analog
            )
            .forEach((sensor) => {
              sensor.lastRequestDateTime = new Date();
              if (!isTerminalDeviceValidForReportSensorValues(terminalDevice)) {
                sensor.lastValue = sensorValue;
                sensor.lastValueAt = sensor.lastRequestDateTime.toISOString();
              }
            });

            updateTerminalDevice(terminalDevice);
        }
      }
    },
    [terminalDevice, updateTerminalDevice]
  );

  const processGetsensorvalueError = useCallback((messageId, message) => {},
  []);

  const processSensorConfigMsg = useCallback((messageId, message) => {
    const targetId = message?.data?.target_id;
    const success = message?.data?.success;
    const sensorsSetup = message?.data?.sensors_setup;

    if(targetId === terminalDevice?.id 
      && success
      && terminalDevice?.sensors instanceof Array
      && sensorsSetup instanceof Array){
      
        let modified = false;
        sensorsSetup.forEach(sensorSetup => {
          const sensor = terminalDevice
          .sensors
          .find(
            sensor =>{
              return sensor?.sensorIndex === sensorSetup?.id - 1 
                && sensor?.sensorId?.physicalCommunicationType === getPhyisicalCommunicationTypeFromBitConfig(sensorSetup?.bitConfig)
              }
          )
          if(sensor){
            sensor.notified = true;
            modified = true;
          }
        })

        if(modified){
          updateTerminalDevice(terminalDevice)
        }
    }
  }, [terminalDevice, updateTerminalDevice])

  const processReportSensorsMsg = useCallback(
    (messageId, message) => {
      const terminalDeviceSensorValues =
        message.data?.terminal_device_sensor_values;

      if (
        terminalDevice?.sensors &&
        terminalDeviceSensorValues?.target_id === terminalDevice?.id &&
        terminalDeviceSensorValues.sensors_value instanceof Array
      ) {
        terminalDeviceSensorValues.sensors_value.forEach((sensorValue) => {
          terminalDevice.sensors
            .filter(
              (sensor) =>
                sensor?.sensorIndex === sensorValue?.sensor_index &&
                sensor?.sensorId?.physicalCommunicationType ===
                  sensorValue?.physical_communication_type
            )
            .forEach((sensor) => {
              if(sensorValue?.measure_values instanceof Array 
                && sensorValue.measure_values.length > 0
              ){
                sensor.lastValue = sensorValue.measure_values[0].value;
              }
              else{
                sensor.lastValue = sensorValue?.voltage_value;
              }
              
              sensor.lastRequestDateTime = new Date();
              sensor.lastValueAt = sensor.lastRequestDateTime.toISOString();
              sensor.isRequesting = false
            });
        });

        updateTerminalDevice(terminalDevice)
      }
    },
    [terminalDevice, updateTerminalDevice]
  );

  //#region TRIGGERS
  const processSetTriggerIds = useCallback(
    (triggerIds, isDeleted) => {
      if (terminalDevice?.sensors instanceof Array) {
        let updated = false;
        terminalDevice.sensors
          .filter(
            (sensor) =>
              sensor?.triggers instanceof Array && sensor.triggers.length > 0
          )
          .flatMap((sensor) => {
            sensor.triggers.forEach(
              (t) => (t.sensorIndex = sensor.sensorIndex)
            );
            return sensor.triggers;
          })
          .filter(
            (trigger) =>
              triggerIds.filter(
                (receivedTriggerId) => receivedTriggerId === trigger?.id
              ) > 0
          )
          .forEach((trigger) => {
            trigger.notified = true;
            trigger.deletedByUser = isDeleted;
            trigger.deletedConfirmationByDevice = isDeleted;
            updated = true;
          });

        if (updated) {
          updateTerminalDevice(terminalDevice)
        }
      }
    },
    [terminalDevice, updateTerminalDevice]
  );

  const onSetTriggersMqttMessage = useCallback(
    (topic, message) => {
      //const terminalId = message?.data?.terminal_id;
      const success = message?.data?.success;
      const triggerIds = message?.data?.trigger_ids;
      const triggerIdsActive = message?.data?.trigger_ids_active;
      if (success && terminalDevice?.sensors instanceof Array) {
        if (triggerIds instanceof Array) {
          processSetTriggerIds(triggerIds, false);
        }
        if (triggerIdsActive instanceof Array) {
          processSetTriggerIds(triggerIdsActive, false);
        }
      }
    },
    [processSetTriggerIds, terminalDevice?.sensors]
  );

  const onDeleteTriggersMqttMessage = useCallback(
    (topic, message) => {
      const success = message?.data?.success;
      const triggerIds = message?.data?.trigger_ids;
      const triggerIdsActive = message?.data?.trigger_ids_active;
      if (success && terminalDevice.sensors instanceof Array) {
        if (triggerIds instanceof Array) {
          processSetTriggerIds(triggerIds, true);
        }
        if (triggerIdsActive instanceof Array) {
          processSetTriggerIds(triggerIdsActive, true);
        }
      }
    },
    [terminalDevice.sensors, processSetTriggerIds]
  );

  const voidMethod = useCallback((topic, message) => {}, []);
  //#endregion

  return (
    <>
      <GetsensorvalueMQTT
        processMsg={processGetsensorvalueMsg}
        processErrorMsg={processGetsensorvalueError}
      ></GetsensorvalueMQTT>
      <ReportSensorsMQTT
        processMsg={processReportSensorsMsg}
      ></ReportSensorsMQTT>
      <SensorsConfigMQTT
        processMsg={processSensorConfigMsg}
      ></SensorsConfigMQTT>

      <SetTriggersMQTT
        processMsg={onSetTriggersMqttMessage}
        processErrorMsg={voidMethod}
      />
      <DeleteTriggersMQTT
        processMsg={onDeleteTriggersMqttMessage}
        processErrorMsg={voidMethod}
      />
      <JoinedReportMQTT
         processMsg={processReportSensorsMsg}
         processErrorMsg={voidMethod}
      />
    </>
  );
};
