import React, { useCallback, useState, useEffect } from "react";
import { useOutputsAPI } from "../../../context/outputs-context";
import { projectsConstants } from "../../../_constants/projects.constants";
import { PaginatedOptions } from "../PaginatedOptions/PaginatedOptions";
import { TerminalDeviceOutputsDropDown } from "../TerminalDevices/Fichas/OutputConfiguration/TerminalDeviceOutputsDropDown";
import { Input } from "reactstrap";

const {
  output_deactivated: OUTPUT_DEACTIVATED,
  output_transition: OUTPUT_TRANSITION,
} = projectsConstants.global.states;

export const UNITS = {
  TIME: "TIME",
  PULSES: "PULSES",
};

export const TerminalDeviceOutputTimeOrCudCalibration = (props) => {
  const {
    measurement,
    onMeasurementChange,
    calibrationOptions,
    terminalDevice,
    unit,
    output,

    rawTitle,
  } = props;
  
  const [state, setState] = useState({
    selectedOutput: output,
  });

  const {
    actoutput,
    advanced_actoutput,
    isValidForAdvancedActoutput,
  } = useOutputsAPI();

  const isActivationEnabled = useCallback(
    (output) => {
      return (
        output?.currentState === OUTPUT_DEACTIVATED &&
        calibrationOptions.includes(measurement?.calibrationRawValue)
      );
    },
    [calibrationOptions, measurement?.calibrationRawValue]
  );

  const ActiveOutput = useCallback(
    (terminalDeviceId, output) => {
      if (isActivationEnabled(output)) {
        if (isValidForAdvancedActoutput(terminalDevice)) {
          advanced_actoutput(
            terminalDeviceId,
            null,
            1 << (output?.output - 1),
            undefined,
            false,
            measurement?.calibrationRawValue * 1000
          );
        } else {
          actoutput(
            terminalDeviceId,
            output.output,
            output.getActivationType(),
            output.activationParams,
            unit,
            undefined,
            false,
            measurement?.calibrationRawValue * 1000
          );
        }
        output.prevState = output.currentState;
        output.currentState = OUTPUT_TRANSITION;
      }
    },
    [isActivationEnabled, isValidForAdvancedActoutput, terminalDevice, advanced_actoutput, measurement?.calibrationRawValue, actoutput, unit]
  );

  const castToPositiveFloat = useCallback((field) => {
    if (("" + field).length > 0 && !isNaN(field)) {
      field = parseFloat(field);
      field = field < 0 ? 0 : field;
      return field;
    }
    return "";
  }, []);

  const onAutomaticCalibrationOptionSelected = useCallback(
    (calibrationRawValue) => {
      onMeasurementChange({...measurement, calibrationRawValue})
    },
    [measurement, onMeasurementChange]
  );


  const onCalibrationUnitChange = useCallback(measurementUnit => {
    onMeasurementChange({...measurement, measurementUnit})
  }, [measurement, onMeasurementChange])

  const onCalibrationUnitValueChange = useCallback(calibrationUnitValue => {
    onMeasurementChange({...measurement, calibrationUnitValue: castToPositiveFloat(calibrationUnitValue)})
  }, [castToPositiveFloat, measurement, onMeasurementChange])

  const onOutputActivationClick = useCallback(() => {
    ActiveOutput(terminalDevice.id, state.selectedOutput);
  }, [ActiveOutput, state.selectedOutput, terminalDevice.id]);

  const onOutputChange = useCallback((outputIndex, output) => {
    setState((state) => {
      return { ...state, selectedOutput: output };
    });
  }, []);

  const getMeasurementUnit = useCallback(() => {
    if(state.selectedOutput.digitalSensor?.measurement?.measurementUnit !== undefined){

      return state.selectedOutput.digitalSensor?.measurement?.measurementUnit
    }

    return measurement?.measurementUnit ?? ""
  }, [measurement?.measurementUnit, state.selectedOutput.digitalSensor?.measurement?.measurementUnit]) 

  const isSensorCalibrationUnitDefinedBySensor = useCallback(() => {
    return state.selectedOutput.digitalSensor?.measurement?.measurementUnit !== undefined
  }, [state.selectedOutput.digitalSensor?.measurement?.measurementUnit])


  const isInvalidCalibrationUnitValue = useCallback(() => {
      return isNaN(measurement?.calibrationUnitValue) || parseFloat(measurement?.calibrationUnitValue) < 0
  }, [measurement?.calibrationUnitValue])

  const isInvalidCalibrationUnit = useCallback(() => {
    const measurementUnit = getMeasurementUnit()
    return measurementUnit === undefined || measurementUnit === null || measurementUnit === ""
  }, [getMeasurementUnit])

  useEffect(() => {
    setState((prev) => ({ ...prev, selectedOutput: output }));

    if(output.digitalSensor?.measurement?.measurementUnit !== undefined
      && output.digitalSensor?.measurement?.measurementUnit !== output?.measurement?.measurementUnit
    ){
      onMeasurementChange({...output?.measurement, measurementUnit: output.digitalSensor?.measurement?.measurementUnit})
    }

  }, [output]);

  return (
    <>
      <PaginatedOptions
        label={rawTitle}
        values={calibrationOptions}
        valueActive={measurement?.calibrationRawValue}
        onOptionClick={onAutomaticCalibrationOptionSelected}
      ></PaginatedOptions>

      <div className="Title_Descripction">
        Salida a activar para el calibrado:
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div style={{ width: "60%" }}>
          <TerminalDeviceOutputsDropDown
            outputs={terminalDevice?.outputs}
            disabled={output !== undefined}
            onChange={onOutputChange}
            selectedOutput={state.selectedOutput}
          ></TerminalDeviceOutputsDropDown>
        </div>
        <div style={{ width: "40%" }}>
          <button
            className={`ButtonOffAll little`}
            disabled={!isActivationEnabled(state.selectedOutput)}
            onClick={(e) => {
              onOutputActivationClick();
            }}
          >
            Activar
          </button>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="childCardDevices2">Transformar a:</div>
        <Input
          type="text"
          name="cud_units"
          id="cud_units"
          placeholder={`l, m3...`}
          className="InputForm descriptionInput"
          onChange={(e) => onCalibrationUnitChange(e.target.value)}
          value={getMeasurementUnit()}
          invalid={isInvalidCalibrationUnit()}
          disabled={isSensorCalibrationUnitDefinedBySensor()}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="childCardDevices2">Enquivalencia en ({measurement?.measurementUnit}):</div>
        <Input
          type="number"
          name="cud_unitsValue"
          id="cud_unitsValue"
          placeholder={`0...`}
          className="InputForm descriptionInput"
          onChange={(e) => onCalibrationUnitValueChange(e.target.value)}
          value={!isNaN(measurement.calibrationUnitValue) ? measurement?.calibrationUnitValue : ""}
          invalid={isInvalidCalibrationUnitValue()}
        />
      </div>
    </>
  );
};
