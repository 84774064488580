import { projectsConstants } from "../../../../../_constants/projects.constants"

export const isSensorSpecificReportTimeAllowed = terminalDevice => {
    if(terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V1
        || terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V4
    ){
        return false
    }

    return true;
}

export const isSensorLiveCalibrationAllowed = terminalDevice => {
    if(terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V1
        || terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V4
    ){
        return false
    }

    return true;
}

export const isSensorDisableAllowed = terminalDevice => {
    if(terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V1
        || terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V4
    ){
        return false
    }

    return true;
}

export const shouldIgnoreSensorNotified = terminalDevice => {
    if(terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V1
        || terminalDevice?.device?.id === projectsConstants.global.devices.LSC_V4
    ){
        return true
    }

    return false;
}