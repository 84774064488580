import React, { useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ProgramsFather } from "../../components/TerminalDevices/Fichas/Programs/ProgramsFather";
import "./PagePrograms.sass";
import { terminalService } from "../../../_services/terminal.service";
import TecoLayout from "../../Layout/TecoLayout";
import { TecoNavbar } from "../../TecoNavbar/TecoNavbar";
import { TecoSidebar } from "../../TecoSidebar/TecoSidebar";
import { DatetimeTriggersView } from "../../components/TerminalDevices/DatetimeTriggers/DatetimeTriggersView";
import { isTerminalDeviceValidForDatetimeTriggers } from "../../components/TerminalDevices/Fichas/Triggers/TerminalDeviceTriggerChecker";

import Programmer from "../../components/TerminalDevices/Programmer/programmer";
import { useTriggers } from "../../../_services/triggers.service";
import { projectsConstants } from "../../../_constants/projects.constants";
import { Spinner } from "reactstrap";
import { usePublisher } from "../../../context/publish-context";
import { MESSAGE_TYPE_ACTION } from "../../../_constants/messageType.constants";
import uuid from "uuid/v4";
import { TerminalDeviceTriggerStatesHandler } from "../../components/TerminalDevices/TerminalDeviceTriggerStatesHandler";


export const PagePrograms = (props) => {
  const location = useLocation();
  const params = useParams();
  const [selectedTerminalDevice, setSelectedTerminalDevice] = useState(
    location?.dataprops?.terminalDevice
  );
  const [terminalId] = useState(params?.terminalId || 0);
  const [goToProgram, setgoToProgram] = useState(undefined);
  const [urlstate, seturlstate] = useState(undefined);
  const [terminalDeviceId] = useState(params?.terminalDeviceId || 0);
  let history = useNavigate();
  const back = useCallback(() => {
    history(
      `/zones/${params?.zoneId}/sector/${params?.sectorId}/terminals/${terminalId}/`
    );
  }, [history, params?.sectorId, params?.zoneId, terminalId]);
  const { publish } = usePublisher();


  const triggersService = useTriggers()
  const [triggers, setTriggers] = useState(undefined)

  useEffect(() => {
    //Navigation page subprogram:
    const programid = params?.programid;

    const dataurl = {
      programid: parseInt(programid),
      zoneId: params?.zoneId,
      sectorId: params?.sectorId,
      terminalId: params?.terminalId,
      terminalDeviceId: params?.terminalDeviceId,
    };
    seturlstate(dataurl);
    setgoToProgram(parseInt(programid));
  }, [
    params?.programid,
    params?.sectorId,
    params?.terminalDeviceId,
    params?.terminalId,
    params?.zoneId,
  ]);

  useEffect(() => {
    if(selectedTerminalDevice !== undefined){
      triggersService.getAll(selectedTerminalDevice?.terminal, selectedTerminalDevice?.id).then(
        (triggers) => {
          triggers = triggers.filter(
            (trigger) =>
              !trigger?.deletedByUser &&
              trigger?.type === projectsConstants.global.triggers.types.program
          )
          .map(trigger => {
            if(trigger.startTimestamp && trigger.endTimestamp) {
              const startDate = new Date(trigger.startTimestamp)
              const endDate = new Date(trigger.endTimestamp)
  
              trigger.duration = (endDate.getTime() - startDate.getTime()) / 1000
            }
  
            return trigger
          })
  
          setTriggers(triggers);
        },
        (error) => {
        }
      );
    }
  }, [selectedTerminalDevice, triggersService])

  useEffect(() => {
    if (!selectedTerminalDevice) {
      terminalService
        .getTerminalDevice(terminalId, terminalDeviceId)
        .then((terminalDevice) => {
          setSelectedTerminalDevice(terminalDevice);
        })
        .catch((error) => {
          console.log("ERROR CARGANDO terminal");
        });
    }
  }, [location]);

  function later(delay) {
    return new Promise(function(resolve) {
        setTimeout(resolve, delay);
    });
  }

  const publishSetTriggers = useCallback((terminalDevice) => {
    publish(projectsConstants.master_outputs.actions.common.settriggers, {
      type: MESSAGE_TYPE_ACTION,
      id: uuid(),
      data: {
        target_id: terminalDevice?.id
      },
    });
  }, [publish])

  const publishDeletrTriggers = useCallback((terminalDevice) => {
    publish(projectsConstants.master_outputs.actions.common.deletetriggers, {
      type: MESSAGE_TYPE_ACTION,
      id: uuid(),
      data: {
        target_id: terminalDevice?.id
      },
    });
  }, [publish])

  const createTrigger = useCallback((terminalDevice, triggerDto) => {
    //return later(500).then(() => ({...triggerDto, id: Math.floor(Math.random() * 10000), notified: false}))
    return triggersService
      .save(terminalDevice?.terminal, terminalDevice?.id, triggerDto)
      .then(createdTriggerDto => {
        if(createdTriggerDto?.notified === false){
          publishSetTriggers(terminalDevice)
        }

        return createdTriggerDto
      })
  }, [triggersService, publishSetTriggers])

  const updateTrigger = useCallback((terminalDevice, triggerDto) => {
    //return later(500).then(() => ({...triggerDto, notified: false}))
    return triggersService
      .update(terminalDevice?.terminal, terminalDevice?.id, triggerDto.id, triggerDto)
      .then(updatedTriggerDto => {
        if(updatedTriggerDto?.notified === false){
          publishSetTriggers(terminalDevice)
        }

        return updatedTriggerDto
      })
  }, [triggersService, publishSetTriggers])

  const deleteTrigger = useCallback((terminalDevice, triggerDto) => {
    //return later(500).then(() => ({...triggerDto, deletedByUser: true}))
    return triggersService
          .deleteTrigger(
            terminalDevice?.terminal,
            terminalDevice?.id,
            triggerDto.id
          )
          .then(updatedTriggerDto => {
            if(updatedTriggerDto?.notified === false){
              publishDeletrTriggers(terminalDevice)
            }
    
            return updatedTriggerDto
          })
  }, [triggersService, publishDeletrTriggers])

  const getDateTimeTriggersProgrammerComponent = useCallback(() => {
    if(triggers !== undefined){
      if(isTerminalDeviceValidForDatetimeTriggers(selectedTerminalDevice)){
        return <DatetimeTriggersView 
          terminalDevice={selectedTerminalDevice}
          onBack={back}
          triggerId={goToProgram}
          projectId={(
              selectedTerminalDevice?.device?.id === projectsConstants.global.devices.LSC_V1 || 
              selectedTerminalDevice?.device?.id === projectsConstants.global.devices.LSC_V4)
            ? projectsConstants.lora_outputs.id
            : projectsConstants.master_outputs.id
          } />;
      }
      else {
        return <ProgramsFather
            terminalDevice={selectedTerminalDevice}
            CancelScheduledTasks={back}
            back={back}
            goToProgram={goToProgram}
            urlstate={urlstate}
          />
      }
    }

    return <Spinner />
  }, [back, createTrigger, deleteTrigger, goToProgram, selectedTerminalDevice, triggers, updateTrigger, urlstate])

  return (
    <>
      <TecoLayout
        routedNavBar={() => (
          <TecoNavbar
            NavMenu={"PagePrograms"}
            zoneId={params?.zoneId}
            terminalId={params?.terminalId}
            terminalDeviceId={params?.terminalDeviceId}
            sectorId={params?.sectorId}
          />
        )}
        routedSideBar={() => <TecoSidebar />}
        key="menu"
      >
        {selectedTerminalDevice && (
          <>
            <div className="HeaderPrograms"></div>
            {getDateTimeTriggersProgrammerComponent()}
          </>
        )}
      </TecoLayout>
    </>
  );
};

/*<Programmer 
              terminalDevice={{ terminalDevice: selectedTerminalDevice }}
              triggers={triggers}
              onAddTrigger = {createTrigger}
              onUpdateTrigger = {updateTrigger}
              onDeleteTrigger = {deleteTrigger} />*/
/* <DatetimeTriggersView terminalDevice={selectedTerminalDevice} onBack={back} triggerId={goToProgram} /> */