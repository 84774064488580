import React, { useCallback, useEffect, useState } from "react";
import { TerminalDeviceSensorsDropDown } from "../SensorsConfiguration/sensorsConf/TerminalDeviceSensorsDropDown";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { DangerComponent } from "../../../../DangerComponent/DangerComponent";

const errors = {
    NONE: "NONE",
    OUTPUT_WITHOUT_MEASUREMENT: "OUTPUT_WITHOUT_MEASUREMENT",
    SENSOR_WITHOUT_MEASUREMENT: "SENSOR_WITHOUT_MEASUREMENT",
    SENSOR_MEASURE_WITH_DIFFERENT_UNIT: "SENSOR_MEASURE_WITH_DIFFERENT_UNIT"
}

const initState = {
    sensor: undefined,
    error: errors.NONE,
    outputMeasurementUnit: "",
    sensorMeasurementUnit: ""
}

export const  TerminalDeviceOutputDigitalSensorAssociate = props => {

    const {terminalDevice, output, onTerminalDeviceSensorSelected} = props

    const [state, setState] = useState({...initState, sensor: output.digitalSensor})

    useEffect(() => {
        setState({...initState, sensor: output.digitalSensor})
    }, [output])

    const onTerminalDeviceSensorSelectedCallback  = useCallback((terminalDeviceSensorIndex, terminalDeviceSensor) => {

        if(!terminalDeviceSensor){
            setState(prev => ({...prev, sensor: terminalDeviceSensor}))
            onTerminalDeviceSensorSelected(terminalDeviceSensor)

            return;
        }

        if(!output?.measurement){
            setState(prev => ({...prev, error: errors.OUTPUT_WITHOUT_MEASUREMENT}))
        }
        else{
            if(!terminalDeviceSensor?.measurement){
                setState(prev => ({...prev, error: errors.SENSOR_WITHOUT_MEASUREMENT}))
            }
            else{
                if(terminalDeviceSensor.measurement?.measurementUnit !== 
                    output.measurement?.measurementUnit
                ){
                    setState(prev => ({
                        ...prev,
                        error: errors.SENSOR_MEASURE_WITH_DIFFERENT_UNIT,
                        outputMeasurementUnit: output.measurement?.measurementUnit,
                        sensorId: terminalDeviceSensorIndex + 1,
                        sensorMeasurementUnit: terminalDeviceSensor.measurement?.measurementUnit
                    }))
                }
                else{
                    setState(prev => ({
                        ...prev,
                        error: errors.NONE,
                        sensor: terminalDeviceSensor
                    }))
                    onTerminalDeviceSensorSelected(terminalDeviceSensor)
                }
            }
        }
        
    }, [onTerminalDeviceSensorSelected, output.measurement])

    return <>
        {state.error === errors.OUTPUT_WITHOUT_MEASUREMENT && <DangerComponent message={"La salida debe tener un calibrado para poder gesitonar las alarmas en relación al sensor."} />}
        {state.error === errors.SENSOR_WITHOUT_MEASUREMENT && <DangerComponent message={"El sensor debe estar calibrado."} />}
        {state.error === errors.SENSOR_MEASURE_WITH_DIFFERENT_UNIT && <DangerComponent message={`El calibrado de la salida (${state.outputMeasurementUnit}) y el calibrado del sensor ${state.sensorId} (${state.sensorMeasurementUnit}) debe medir las mismas unidades.`} />}
        <TerminalDeviceSensorsDropDown 
            sensors={terminalDevice?.sensors}
            physicalCommunicationTypes={[projectsConstants.global.sensors.phys.digital, projectsConstants.global.sensors.phys.cuds]}
            onSelected={onTerminalDeviceSensorSelectedCallback}
            selectedSensor={state.sensor}
            filterOnlyActiveAndConfigured={true}
            useSensorTypeDescpritionIfEmtpyCustomDescription={false}
            isClearable={true}
        />
    </>
}