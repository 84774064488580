import React, { useState, useEffect, useCallback } from "react";
import { sensorRegistersType, sensorService } from "../../../../../_services/sensors.service";
import { useApp } from "../../../../../context/app-context";

import "./MultipleStatistics.sass";
import "./LiquidBarchart.sass";
import "./Statistics.sass";
import {
  getDayOfYearFormatted,
  getDateTimeFormatted,
  getHoursMinuteTimeFormatted
} from "../../../../../_helpers/DateFormatHelper";
import { outputRegistersType, OutputService } from "../../../../../_services/outputcontroller.service";
import { TerminalDeviceSensorLineGraph } from "../../SensorsView/TerminalDeviceSensorLineGraph";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import * as XLSX from "xlsx";
import { periods, PeriodSelector } from "../../../date/PeriodSelector";
import { DangerComponent } from "../../../../DangerComponent/DangerComponent";
import { BreakLine } from "../../../break/BreakLine";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import { useTerminalDevice } from "../../../../../context/terminal-device-context";

const isTheSameSensorId = (source, target) => {
  return source?.sensorIndex === target?.sensorIndex 
  && source?.sensorId?.physicalCommunicationType === target?.sensorId?.physicalCommunicationType
  && source?.sensorId?.id === target?.sensorId?.id
}
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate(); // Obtiene el último día del mes
};

// Función auxiliar para obtener la semana del año de una fecha
const getWeekOfYear = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000; // Número de días desde el 1 de enero
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const TerminalDeviceSensorStats = (props) => {
  const { sensor: initialSensor, output: initialOutput } = props;

  const [state, setState] = useState({
    day: new Date(),
    period: periods.DAY
  })
  const [data, setData] = useState(undefined);
  const [showAccumulated, setShowAccumulated] = useState(false);
  const [sensor, setSensor] = useState(initialSensor)
  const [output, setOutput] = useState(initialOutput)

  const { selectedTerminal } = useApp();
  const { terminalDevice } = useTerminalDevice()

  useEffect(() => {
    if(!(isTheSameSensorId(initialSensor, sensor))){
      setSensor(initialSensor)
    }
  }, [initialSensor])

  useEffect(() => {
    if(initialOutput?.output !== output?.output){
      setOutput(initialOutput)
    }
  }, [initialOutput])

  const isValidForAccumulated = useCallback(() => {
    return (output ||
      sensor?.sensorId?.physicalCommunicationType ===
        projectsConstants.global.sensors.phys.cuds ||
      sensor?.sensorId?.physicalCommunicationType ===
        projectsConstants.global.sensors.phys.digital)
  }, [output, sensor?.sensorId?.physicalCommunicationType])

  const getRequestParams = useCallback(() => {
    const from = new Date(state.day)
    const to = new Date(state.day)
    let type = sensorRegistersType.MAIN
    to.setDate(from.getDate() + 1)

    if(output){
      if(state.period === periods.WEEK){
        type = outputRegistersType.MAIN
      }
      else if(state.period === periods.MONTH){
        type = outputRegistersType.DAY_ACCUMULATED
      }
      else if(state.period === periods.YEAR){
        type = outputRegistersType.WEEK_ACCUMULATED
      }
      else if(state.period === periods.TOTAL){
        type = outputRegistersType.YEAR_ACCUMULATED
      }
      else{
        type = outputRegistersType.MAIN
      }
    }
    else if(isValidForAccumulated()) {
      if(state.period === periods.WEEK){
        type = sensorRegistersType.MAIN
      }
      else if(state.period === periods.MONTH){
        type = sensorRegistersType.DAY_ACCUMULATED
      }
      else if(state.period === periods.YEAR){
        type = sensorRegistersType.WEEK_ACCUMULATED
      }
      else if(state.period === periods.TOTAL){
        type = sensorRegistersType.YEAR_ACCUMULATED
      }
    }
    else {
      if(state.period === periods.WEEK){
        type =  sensorRegistersType.MAIN
      }
      else if(state.period === periods.MONTH){
        type = sensorRegistersType.DAY_AVERAGE
      }
      else if(state.period === periods.YEAR){
        type = sensorRegistersType.WEEK_AVERAGE
      }
      else if(state.period === periods.TOTAL){
        type = sensorRegistersType.YEAR_AVERAGE
      }
    }

    if(state.period === periods.WEEK){
      to.setDate(from.getDate() + 7)
      return {from, to, type}
    }
    else if(state.period === periods.MONTH){
      to.setMonth(from.getMonth() + 1)
      return {from, to, type}
    }
    else if(state.period === periods.YEAR){
      to.setMonth(from.getMonth() + 12)
      return {from, to, type}
    }
    else if(state.period === periods.TOTAL){
      return {from: new Date(0), to, type}
    }

    return {from, to, type}
  
  }, [isValidForAccumulated, output, state.day, state.period])

  const groupMeasureByOutputState = useCallback(measures => {
    const result = [];
    let currentGroup = null;

    measures?.forEach((measure, index) => {
      const outputsState = measure.outputsState !== undefined ? measure.outputsState : null;

      if (outputsState === null || outputsState <= 0) {
        return; // Ignoramos los objetos con outputsState undefined o null
      }

      // Si no hay grupo actual o si el outputsState es diferente, empezamos un nuevo grupo
      if (!currentGroup || currentGroup.outputsState !== outputsState) {
        // Si ya había un grupo en proceso, cerramos el anterior
        if (currentGroup) {
          currentGroup.endAt = measures[index - 1].date;
          currentGroup.x2 = measures[index - 1].timestamp;
          currentGroup.y2 = measures[index - 1].value;
          result.push(currentGroup);
        }

        // Iniciamos un nuevo grupo con el objeto actual
        currentGroup = {
          startAt: measure.date,
          endAt: null, // Se asignará cuando encontremos el último elemento del grupo
          x1: measure.timestamp,
          x2: null, // Se asignará cuando encontremos el último elemento del grupo
          y1: measure.value,
          y2: null, // Se asignará cuando encontremos el último elemento del grupo
          outputsState: outputsState,
        };
      }
    });

    // Añadimos el último grupo si estaba en proceso
    if (currentGroup) {
      currentGroup.endAt = measures[measures.length - 1].date;
      currentGroup.x2 = measures[measures.length - 1].name;
      currentGroup.y2 = measures[measures.length - 1].value;
      result.push(currentGroup);
    }

    return result;
  }, [])

  const updateOutputsStateReferences = useCallback((meauresByUnit) => {
    if(meauresByUnit){
      Object.keys(meauresByUnit)
      .forEach((unit, index) => {
        const unitMeasures = meauresByUnit[unit]
        unitMeasures.outputStatesReferences = groupMeasureByOutputState(unitMeasures.measures)
        unitMeasures.outputStatesAccumulatedReferences = groupMeasureByOutputState(unitMeasures.accumulatedMeasures)
      })
    }
  }, [groupMeasureByOutputState])

  const getRegisterMeasureSource = useCallback((register, registerType) => {
    if(output){
      switch(registerType){
        case outputRegistersType.DAY_ACCUMULATED:
        case outputRegistersType.WEEK_ACCUMULATED:
        case outputRegistersType.MONTH_ACCUMULATED:
        case outputRegistersType.YEAR_ACCUMULATED:
        case outputRegistersType.TOTAL_ACCUMULATED:
          return register.accumulated?.accumulatedMeasures
        default:
          return register.measures
      }
    }

    switch(registerType){
      case sensorRegistersType.DAY_ACCUMULATED:
      case sensorRegistersType.WEEK_ACCUMULATED:
      case sensorRegistersType.MONTH_ACCUMULATED:
      case sensorRegistersType.YEAR_ACCUMULATED:
      case sensorRegistersType.TOTAL_ACCUMULATED:
        return register.accumulated?.accumulatedMeasures
      default:
        return register.measures
    }
  }, [output])

  useEffect(() => {
    if (selectedTerminal?.id) {
      let future;
      const {from, to, type} = getRequestParams()
      if (output?.terminalDevice && output?.output > 0) {
        future = OutputService.getTerminalDeviceOutputRegistersByPeriod(
          selectedTerminal.id,
          output.terminalDevice,
          output?.output,
          getDayOfYearFormatted(from),
          getDayOfYearFormatted(to),
          type
        );
      } else if (
        sensor?.terminalDevice &&
        sensor?.sensorId?.id &&
        sensor?.sensorIndex !== undefined
      ) {
       
        future = sensorService.getTerminalDeviceSensorRegistersByPeriod(
          selectedTerminal.id,
          sensor.terminalDevice,
          sensor.sensorId.id,
          sensor.sensorIndex,
          getDayOfYearFormatted(from),
          getDayOfYearFormatted(to),
          type
        );
      }

      if (future) {
        future.then(
          (registers) => {
            if (registers instanceof Array) {
              // Inicializamos un objeto vacío donde vamos a almacenar los arrays por unidad.
              const measuresByUnit = {};
              // Iteramos sobre cada entrada del array data.
              registers
                .sort(
                  (aRegister, bRegister) =>
                    new Date(Date.parse(aRegister.receivedAt)) -
                    new Date(Date.parse(bRegister.receivedAt))
                )
                .forEach((reg) => {
                  const date = new Date(Date.parse(reg.receivedAt));

                  if (reg.measures?.length > 0) {
                    const measuresSource = getRegisterMeasureSource(reg, type)
                    measuresSource?.forEach((measure) => {
                      // Si el array de la unidad aún no existe en el objeto measuresByUnit, lo creamos.
                      if (!measuresByUnit[measure.unit]) {
                        measuresByUnit[measure.unit] = {
                          units: [measure.unit],
                          measures: [],
                          accumulated: 0,
                          accumulatedMeasures: [],
                        };
                      }

                      measure.date = date;
                      measure.outputsState = reg.outputsState
                      measure.timestamp = date.getTime();
                      measure[measure.unit] = measure.value;
                      if (
                        (type === sensorRegistersType.MAIN || type === outputRegistersType.MAIN) &&
                        measure.averages instanceof Array
                      ) {

                        const dailyAverage = measure
                          .averages
                          .find(average => average.averageType === "daily")

                        if(dailyAverage?.average !== undefined &&
                          dailyAverage?.average !== null &&
                          !isNaN(dailyAverage.average))
                        {
                            const averageUnit = `(${measure.unit})/dia`;
                            measure[averageUnit] = dailyAverage.average;

                            if (
                              measuresByUnit[measure.unit].units.includes(
                                averageUnit
                              ) === false
                            ) {
                              measuresByUnit[measure.unit].units.push(averageUnit);
                            }
                        }
                      }

                      // Añadimos la medida al array correspondiente a su unidad.
                      measuresByUnit[measure.unit].measures.push(measure);

                      let accumulatedMeasure = { ...measure };
                      const accumulatedNew =
                        measuresByUnit[measure.unit].accumulated +
                        measure[measure.unit];
                      accumulatedMeasure[measure.unit] = accumulatedNew;
                      measuresByUnit[measure.unit].accumulated = accumulatedNew;
                      measuresByUnit[measure.unit].accumulatedMeasures.push(
                        accumulatedMeasure
                      );
                    });
                  } else {
                    measuresByUnit["ud"] = measuresByUnit["ud"] || {
                      units: ["ud"],
                      measures: [],
                      accumulated: 0,
                      accumulatedMeasures: [],
                      outputsState: reg.outputsState
                    };
                    measuresByUnit["ud"].measures.push({
                      value: reg.rawValue,
                      unit: "ud",
                      date,
                      outputsState: reg.outputsState
                    });
                  }
                });

              updateOutputsStateReferences(measuresByUnit)
              setData(measuresByUnit);
            }
          },
          (error) => {
            setData([]);
          }
        );
      }
    }
  }, [sensor, state, selectedTerminal.id, output, getRequestParams, updateOutputsStateReferences]);

  const onPeriodChange = useCallback(({period, day}) => {
    setState(current => ({...current, period, day}))
    setData(undefined)
  }, [])

  const downloadTerminalDeviceSensorData = useCallback(
    (terminalDeviceSensorData) => {
      const { type } = getRequestParams()
      const sortedOutputs = terminalDevice.outputs
        ?.sort((a, b) => a?.output - b?.output)
        const shouldAppendOutputStates = type === sensorRegistersType.MAIN || type === outputRegistersType.MAIN

      Object.keys(terminalDeviceSensorData).forEach((unit) => {
        const data = terminalDeviceSensorData[unit];
  
        // 1. Preparar los datos para el archivo Excel
        const worksheetData = [];
        
        // 2. Agregar los encabezados
        let headers = ["Fecha", "Valor", "Media", "Unidad"]

        if(shouldAppendOutputStates){
          sortedOutputs?.forEach(output => {
            headers.push( `Salida ${output?.output}: ${output?.description || ""}`)
          })
        }
       

        worksheetData.push(headers)
        
        // 3. Añadir los datos
        data.measures.forEach((item) => {
          let values = [
            `${item.date.toLocaleDateString()}-${item.date.toLocaleTimeString()}`,
            item.value,
            item.average,
            unit
          ]

          if(shouldAppendOutputStates){
            sortedOutputs?.forEach(output => {
              if(!isNaN(item.outputsState) && !isNaN(output?.output)){

                if(item.outputsState & (1 << (output.output - 1))){
                  values.push("On")
                }
                else{
                  values.push("Off")
                }

              }
              else{
                values.push("")
              }
            })
          }
          worksheetData.push(values);
        });
        
        // 4. Crear la hoja de cálculo (worksheet) y el libro de trabajo (workbook)
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "DatosSensor");
  
        // 5. Convertir el libro de trabajo a un archivo .xls
        const xlsData = XLSX.write(workbook, { bookType: "xls", type: "array" });
  
        // 6. Crear un blob con el contenido de Excel
        const blob = new Blob([xlsData], { type: "application/vnd.ms-excel" });
  
        // 7. Crear un enlace temporal para la descarga
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${selectedTerminal?.description}_${sensor?.description ?? ""}(S${sensor?.sensorIndex + 1})_${unit}_${state.period}_${getDateTimeFormatted(state.day)}.xls`;
  
        // 8. Simular el click para iniciar la descarga
        document.body.appendChild(link);
        link.click();
  
        // 9. Remover el enlace temporal
        document.body.removeChild(link);
      });
    },
    [state.day, selectedTerminal?.description, sensor?.description, sensor?.sensorIndex, state.period, terminalDevice, getRequestParams]
  );

  const getData = useCallback((unit) => {
    const measuresSource = showAccumulated ? data[unit]?.accumulatedMeasures : data[unit]?.measures
    if (state.period === periods.MONTH){
      const year = state.day.getFullYear();
      const month = state.day.getMonth();
      const daysInMonth = getDaysInMonth(year, month); // Obtiene el número de días en el mes
  
      return Array.from({ length: daysInMonth }, (_, index) => {
        const day = new Date(year, month, index + 1); // Crea una fecha para cada día del mes
        const measureForDay = measuresSource?.find((accumulatedMeasure) => {
          const measureDay = new Date(accumulatedMeasure.date).getDate();
          return measureDay === day.getDate(); // Comparar día del mes
        });
  
        return measureForDay
          ? { ...measureForDay, timestamp: day.getTime() }
          : {
              timestamp: day.getTime(),
              date: day, // Asigna la fecha del día
              unit, // Unidad predeterminada
              [unit]: 0,
              value: 0
            };
      });
    }
    else if(state.period === periods.YEAR){
      const year = state.day.getFullYear();
    
      // Función auxiliar para obtener la fecha de inicio de la semana
      const getStartOfWeek = (weekNumber, year) => {
        const firstDayOfYear = new Date(year, 0, 1); // 1 de enero
        const daysOffset = (weekNumber - 1) * 7; // Número de días a sumar
        const firstWeekStart = firstDayOfYear.getDay() === 0 ? firstDayOfYear : new Date(firstDayOfYear.setDate(firstDayOfYear.getDate() - firstDayOfYear.getDay() + 1)); // Ajuste para que la semana comience en lunes
        return new Date(firstWeekStart.setDate(firstWeekStart.getDate() + daysOffset));
      };
    
      // Lista de semanas (1 al 52/53)
      const totalWeeks = (getWeekOfYear(new Date(year, 11, 31)) === 53) ? 53 : 52;
      return Array.from({ length: totalWeeks }, (_, index) => {
        const weekNumber = index + 1;
        const startOfWeek = getStartOfWeek(weekNumber, year);
    
        // Buscar si hay un accumulatedMeasure correspondiente a la semana
        const measureForWeek = measuresSource?.find((accumulatedMeasure) => {
          const measureDate = new Date(accumulatedMeasure.date);
          const measureWeek = getWeekOfYear(measureDate); // Obtener la semana del año de la medida
          return measureWeek === weekNumber;
        });
    
        // Si hay medida para la semana, retornamos, si no, retornamos un accumulatedMeasure vacío
        return measureForWeek ?? {
          timestamp: startOfWeek.getTime(),
          date: startOfWeek, // Fecha de inicio de la semana
          unit, // Unidad predeterminada
          [unit]: 0,
          value: 0
        };
      });
    }
  
    return measuresSource || [];
  }, [data, showAccumulated, state.day, state.period]);

  const getAccumulated = useCallback(unit => {
    const length = data[unit]?.accumulatedMeasures?.length || 0
    return length > 0 ? (data[unit].accumulatedMeasures[length - 1][unit] ?? 0) : 0
  }, [data])

  /*const getReferenceAreas = useCallback((unitMeasures) => {
    return []
    if(state.period !== periods.DAY){
      return []
    }

    const outputsStateSource = (showAccumulated ? unitMeasures?.outputStatesAccumulatedReferences : unitMeasures?.outputStatesReferences) || []

    return outputsStateSource
      //.filter(state => (state.outputsState & 0b1) > 0)
      .map((outputsStateReference, index) => {
        return <ReferenceLine
        x={outputsStateReference.x1}
        stroke="red"
        strokeDasharray="3 3" // Linea intermitente
      />/*<ReferenceArea 
          key={index}
          x1={outputsStateReference.x1} 
          x2={outputsStateReference.x2} 
          y1={outputsStateReference.y1} 
          y2={outputsStateReference.y2} 
          stroke="red" 
          strokeOpacity={0.3}>
            <Label value="Salidas 1,2,3,4" />
          </ReferenceArea>//
      })    
  }, [state.period, showAccumulated])*/

  const getXAxisTimeFormat = useCallback(() => {
    switch(state.period){
      case periods.MONTH:
        return "dd"
      case periods.YEAR:
        return "dd-MM"
      case periods.TOTAL:
        return "yyyy"
      default:
        return "dd HH:mm"
    }
  }, [state.period])

  return (
    <>
      <div className="TitleMultipleStatics">
        <PeriodSelector onPeriodChange={onPeriodChange}/>
        { isValidForAccumulated() && (
          <div
            className="Button Secondary"
            style={{ width: "30%" }}
            onClick={(e) => setShowAccumulated((current) => !current)}
          >
            {showAccumulated ? "Intervalo" : "Acumulado"}
          </div>
        )}
        <div
          className="Button Secondary"
          onClick={(e) => downloadTerminalDeviceSensorData(data)}
        >
          <i className="fa fa-download fa-1" aria-hidden="true"></i>
        </div>

      </div>
      {!data && <div style={{textAlign: "center"}}><Spinner /></div>}
      {data && Object.keys(data).length === 0 &&  <>
        <DangerComponent message={"No hay datos disponibles"} />
        <BreakLine />
      </>}
      {data && Object.keys(data).map((unit, index) => {
        return (
          <div key={index}>
            {/* Aquí se muestra el badge con el acumulado final */}
            {isValidForAccumulated() && (
              <div style={{textAlign: "center"}}>
                <div className="badge badge-info">
                  Acumulado: {getAccumulated(unit)?.toFixed(2)}{unit}
                </div>
              </div>
            )}
            <TerminalDeviceSensorLineGraph
              key={index}
              data={getData(unit)}
              yData={data[unit].units}
              yReferenceLines={
                sensor?.triggers instanceof Array &&
                sensor.triggers.filter((trigger) => !trigger?.deletedByUser)
              }
              //referenceAreas={getReferenceAreas(data[unit])}
              timeFormat={getXAxisTimeFormat()}
            />
            <BreakLine />
          </div>
        );
      })}
    </>
  );
};
