import React from "react"
import { format } from "date-fns";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export const TerminalDeviceSensorLineGraph = props => {

    const {data, xLabel, yData, yReferenceLines, referenceAreas, timeFormat} = props

    const lineColors = ["#75A3FF", "#424656", "#A6ABBD", "#FB7D94", "#BF4661"];
    const refLineColors = ["#52414c", "#596157", "#5b8c5a", "#e3655b", "#cfd186"];

    return <div className={"statisticsfatherTSMc"}>
      <ResponsiveContainer height={350} className={"statisticsfather"}>
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="timestamp" 
            angle={45} 
            textAnchor="start" 
            interval="preserveStartEnd"
            scale="time"
            type="number"
            domain={["dataMin", "dataMax"]} // Define el rango en el que se mostrarán las fechas
            tickFormatter={(timestamp) => format(new Date(timestamp || 0), timeFormat || "HH:MM")}
            tick={{ fontSize: 12 }}
            >
            {xLabel}
          </XAxis>
          <YAxis
            domain={["dataMin", "dataMax"]}
            tickFormatter={(tick) => Math.round(tick * 100) / 100}
          />
          <Tooltip
            labelFormatter={timestamp => format(new Date(timestamp || 0), timeFormat || "HH:mm")} // Formato en el Tooltip (día horas:minutos)
          />
          <Legend verticalAlign="top" />
          {yReferenceLines instanceof Array && (
                yReferenceLines
                .map((value, index) => (
                  <ReferenceLine
                    key={index}
                    y={value}
                    stroke={refLineColors[index % refLineColors.length]}
                  ></ReferenceLine>
                ))
            )}
          {yData.map((measure, index) => {
            return (
              <Line
                key={index}
                type="monotone"
                name={measure}
                dataKey={measure}
                stroke={lineColors[lineColors.length % index]}
                dot={false}
              />
            );
          })}
          {referenceAreas}
        </LineChart>
      </ResponsiveContainer>
    </div>
}