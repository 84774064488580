
import React, { useCallback } from "react";
import { CardSensor } from "./CardSensor/CardSensor";

export const TerminalDeviceSensorGenericView = props => {
    const {
    terminalDevice,
    physicalCommunicationType,
    onSensorClick
    } = props

    const getSensorCards = useCallback(() => {
    if (terminalDevice?.sensors instanceof Array) {
        let cardSensors = terminalDevice.sensors
        .sort( (a, b) => a?.sensorIndex - b?.sensorIndex)
        .filter(
            (sensor) =>
            sensor?.active &&
            sensor?.sensorId?.physicalCommunicationType === physicalCommunicationType
        )
        .flatMap(sensor => sensor.measures.map(measure => ({...sensor, measure})))
        .filter(wrapSensorMeasure => !!(wrapSensorMeasure.measure))
        .map((wrapSensorMeasure, index) => {
            return (
            <CardSensor
                key={index}
                physicalCommunicationType={physicalCommunicationType}
                id={wrapSensorMeasure.id}
                sensorIndex={wrapSensorMeasure.sensorIndex}
                sensorValue={wrapSensorMeasure.measure.value}
                sensorUnit={wrapSensorMeasure.measure.unit}
                loading={wrapSensorMeasure?.isRequesting}
                dateTime={
                wrapSensorMeasure.lastValueAt &&
                new Date(wrapSensorMeasure.lastValueAt)
                }
                onClick={onSensorClick}
                allowSwitchToAverage={!isNaN(wrapSensorMeasure.measure.dailyAverage)}
                dialyAverageSensorValue={wrapSensorMeasure.measure.dailyAverage}
                dailyMinSensorValue={wrapSensorMeasure.measure.dailyMin}
                dailyMaxSensorValue={wrapSensorMeasure.measure.dailyMax}
            />
            );
        });

        return cardSensors
    }
    return <>No hay sensores analógicos configurados.</>;
    }, [onSensorClick, physicalCommunicationType, terminalDevice.sensors]);

    return <>
    <div className="sensorsCardFather">{getSensorCards(terminalDevice)}</div>
    </>;
};