export const projectsConstants = {
  master_outputs: {
    id: "master_outputs",
    api_path: process.env.API_PATH_MASTER_OUTPUTS,
    all_outputs: 0,
    actions: {
      setworkmode: "setworkmode",
      maintenance: "maintenance",
      setoutputv: "setoutputv",
      setupi2csensors: "setupi2csensors",
      calibratesensor: "calibratesensor",
      changei2csensoraddr: "changei2csensoraddr",
      actoutput: "actoutput",
      advanced_actoutput: "advanced_actoutput",
      setprogram: "setprogram",
      deleteprogram: "deleteprogram",
      settasks: "settasks",
      settasksbyid: "settasksbyid",
      deletetasks: "deletetasks",
      endoutput: "endoutput",
      updatesstate: "updatesstate",
      updateoutputs: "updateoutputs",
      getonlineterminals: "getonlineterminals",
      getsensorvalue: "getsensorvalue",
      
      tsm:{
        tsm_getsensorvalue: "tsm_getsensorvalue",
        tsm_getsensorstatus: "tsm_getsensorstatus",
        tsm_updatestates: "tsm_updatestates",
        tsm_calibratesensor: "tsm_calibratesensor",
        tsm_getstates: "tsm_getstates",
        tsm_calibrationreport: "tsm_calibrationreport"
      },
      common:{
        settriggers: "settriggers",
        sensorsconfig: "sensorsconfig",
        deletetriggers: "deletetriggers",
        reportsensors: "reportsensors",
        joined_report: "joined_report"
      }
    },
    tsm: {
      points: {
        one: "one",
        two: "two",
        three: "three",
      }
    },
    versions: {
      MMS_HW1_MIN_REPORTSENSORVALUES: 39,
      MMS_HW3_MIN_REPORTSENSORVALUES: 35,
      SIO_HW1_MIN_REPORTSENSORVALUES: 22,
      SIO_HW3_MIN_REPORTSENSORVALUES: 19,
      
      MMS_HW1_MIN_TRIGGERS: 39,
      MMS_HW3_MIN_TRIGGERS: 39,
      SIO_HW1_MIN_TRIGGERS: 22,
      SIO_HW3_MIN_TRIGGERS: 33,

      MMS_HW1_MIN_DATETIME_TRIGGERS: 39,
      MMS_HW3_MIN_DATETIME_TRIGGERS: 43,
      SIO_HW1_MIN_DATETIME_TRIGGERS: 22,
      SIO_HW3_MIN_DATETIME_TRIGGERS: 21,

      MMS_HW1_MIN_DATETIME_TRIGGERS_WITH_SECONDS: 49,
      MMS_HW3_MIN_DATETIME_TRIGGERS_WITH_SECONDS: 81,
      SIO_HW1_MIN_DATETIME_TRIGGERS_WITH_SECONDS: 25,
      SIO_HW3_MIN_DATETIME_TRIGGERS_WITH_SECONDS: 45,

      MMS_HW1_MIN_DATETIME_TRIGGERS_WITH_LOOP: 49,
      MMS_HW3_MIN_DATETIME_TRIGGERS_WITH_LOOP: 81,
      SIO_HW1_MIN_DATETIME_TRIGGERS_WITH_LOOP: 25,
      SIO_HW3_MIN_DATETIME_TRIGGERS_WITH_LOOP: 45,

      MMS_HW1_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET: 87,
      MMS_HW3_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET: 87,
      MMS_HW5_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET: 10,
      SIO_HW1_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET: 49,
      SIO_HW3_MIN_DATETIME_TRIGGERS_ACTIVATIONS_WITH_OFFSET: 999999999,

      MMS_HW1_MIN_REPORT_TRIGGERS: 39,
      MMS_HW3_MIN_REPORT_TRIGGERS: 53,
      SIO_HW1_MIN_REPORT_TRIGGERS: 22,
      SIO_HW3_MIN_REPORT_TRIGGERS: 33,

      MMS_HW1_MIN_SETWORKMODE_V2: 39,
      MMS_HW3_MIN_SETWORKMODE_V2: 41,
      SIO_HW1_MIN_SETWORKMODE_V2: 22,
      SIO_HW3_MIN_SETWORKMODE_V2: 21,
      PKT7_MIN_SETWORKMODE_v2: 3,

      MMS_HW1_MIN_ADVANCED_ACTOUTPUT: 39,
      MMS_HW3_MIN_ADVANCED_ACTOUTPUT: 43,
      SIO_HW1_MIN_ADVANCED_ACTOUTPUT: 22,
      SIO_HW3_MIN_ADVANCED_ACTOUTPUT: 21,

      MMS_HW1_MIN_MA_ANALOG_INPUT_TYPE: 45,
      MMS_HW3_MIN_MA_ANALOG_INPUT_TYPE: 1,
      SIO_HW1_MIN_MA_ANALOG_INPUT_TYPE: 22,
      SIO_HW3_MIN_MA_ANALOG_INPUT_TYPE: 1,

      MMS_HW1_MIN_DIGITAL_SENSORS_TRIGGERS: 39,
      MMS_HW3_MIN_DIGITAL_SENSORS_TRIGGERS: 43,
      SIO_HW1_MIN_DIGITAL_SENSORS_TRIGGERS: 22,
      SIO_HW3_MIN_DIGITAL_SENSORS_TRIGGERS: 21,

      MMS_HW1_MIN_OUTPUTS_CALIBRATION: 39,
      MMS_HW3_MIN_OUTPUTS_CALIBRATION: 49,
      SIO_HW1_MIN_OUTPUTS_CALIBRATION: 22,
      SIO_HW3_MIN_OUTPUTS_CALIBRATION: 27,

      MMS_HW1_MIN_DIGITAL_SENSOR_ASSOCIATE: 85,
      MMS_HW3_MIN_DIGITAL_SENSOR_ASSOCIATE: 999999999,
      MMS_HW5_MIN_DIGITAL_SENSOR_ASSOCIATE: 10,
      SIO_HW1_MIN_DIGITAL_SENSOR_ASSOCIATE: 49,
      SIO_HW3_MIN_DIGITAL_SENSOR_ASSOCIATE: 999999999,
    }
  },
  lora_outputs: {
    id: "lora_outputs",
    api_path: process.env.API_PATH_LORA_OUTPUTS,

    versions: {
      LSC_V4_HW1_MIN_DIGITAL_SENSOR_ASSOCIATE : 13
    },

    actions: {
      actoutput: "actoutput",
      endoutput: "endoutput",
      updateoutputs: "updateoutputs",
      updatemeterdata: "updatemeterdata",

      syncdeviceconfig: "syncdeviceconfig",
      dynamic_payload: "dynamic_payload",
      getonlineterminals: "getonlineterminals",
      settriggers: "settriggers"
    },
    dosificationPremises: {
      none: "NONE",
      dayOnly: "DAY_ONLY",
      nightOnly : "NIGHT_ONLY",
      timeDiscrimination: "TIME_DISCRIMINATION"
    },
    clockSyncStates: {
      pending: "PENDING",
      foceResyncEnqueued: "FORCE_RESYNC_ENQUEUED",
      forceResyncSent: "FORCE_RESYNC_SENT",
      appTimeReqReceived: "APP_TIME_REQ_RECEIVED",
      appTimeAnsEnqueued: "APP_TIME_ANS_ENQUEUED",
      timePeriodicityReqEnqueued: "TIME_PERIODICITY_REQ_ENQUEUED",
      timePeriodidityReqSent: "TIME_PERIODICITY_REQ_SENT",
      done: "DONE"
    },
    syncStates: {
      sync: "SYNC",
      waitingToSend: "WAITING_TO_SEND",
      waitingResponse: "WAITING_RESPONSE",
      notSync: "NOT_SYNC",
    }
  },
  postigel: {
    id: "postigel",
    actions: {
      specconfig: "specconfig",
      speccalibration: "speccalibration",
    }
  },
  global: {
    devices: {
      SIO: "SIO_120218",
      MMS: "MMS_100218",
      TSM: "TSM_070121",
      PKT7: "PKT_050514_X1",
      LORA: "LRA_170519",
      LORA_V2: "LRA_170519_V2",
      TL01_TL03: "TL01_TL03",
      MMS_X5: "MMS_100218_X5_BLE",
      LSC_V1: "LSC_271223_O2_X1",
      LSC_V4: "LSC_271223_O2_X4"
    },
    voltages: {
      V12: "V12",
      V24: "V24"
    },
    codes: {
      ACK: 0,
      NACK: 5
    },
    states: {
      output_active: "ACTIVE",
      output_activated: "ACTIVATED",
      output_deactivated: "DEACTIVATED",
      output_transition: "TRANSITION1",
    },
    activations: {
      continuous: "CONTINUOUS",
      pulses: "PULSES",
      pulses_with_polarity: "PULSES_WITH_POLARITY",
      pulses_per_minute: "PULSES_PER_MINUTE"

    },
    sensors: {
      name: {
        cuds: "GENERIC_CUD_SENSOR",
        fake_cud: "GENERIC_CUD_SENSOR",
        fake_digital: "FAKE_DIGITAL_SENSOR",
        generic_voltage: "GENERIC_VOLTAGE",
        generic_milli_amps: "GENERIC_MILLI_AMPS"
      },
      phys:{
        analog: "ANALOG",
        cuds: "CUD",
        tsm: "TSM",
        digital: "DIGITAL",
        RS485: "RS485",
        I2C: "I2C",
        ADC: "ADC"
      },
      analogInputType: {
        voltage: "V",
        current: "mA"
      },
      units: {
        "%": {
          badge: "humedad",
          className: "badge-humidity",
          icon: "humidity"
        },
        "ºC":{
          badge: "temp",
          className: "badge-temp",
          icon: "temp"
        },
        "Bar":{
          badge: "presión",
          className: "badge-press",
          icon: "press"
        },
        "pH": {
          badge: "pH",
          className: "badge-pH",
          icon: "PH",
        },
        "mV": {
          badge: "mV",
          className: "badge-mV",
          icon: "mV",
        },
        "uS/cm": {
          badge: "uS/cm",
          className: "badge-usCm",
          icon: "uS/cm",
        },
        "ORP":{
          badge: "ORP",
          className: "badge-ORP",
          icon: "ORP"
        },
        "EC":{
          badge: "EC",
          className: "badge-EC",
          icon: "EC"
        },
        "DO":{
          badge: "DO",
          className: "badge-DO",
          icon: "DO"
        },
        "ISE":{
          badge: "ISE",
          className: "badge-ISE",
          icon: "ISE"
        },
        "l":{
          badge: "litros",
          className: "badge-lit",
          icon: "press"
        }
        
      },
      states: {
        idle: "IDLE",
	      calibrating: "CALIBRATING",
	      measuring: "MEASURING",
        measuring_not_stable: "MEASURING_NOT_STABLE"
      },
      errors :{
        none: "NONE",
        calibratig: "CALIBRATING"
      },
    },
 
    programs : {
      trigger_types: { 
        time: "TIME"
      }
    },
    triggers: {
      types:{
        sensor: "SENSOR",
        program: "PROGRAM"
      },

      // NUMERO MAXIMO DE TRIGGERS
      MMS_HW1_MAX_TRIGGERS_SIZE: 10,
      MMS_HW3_MAX_TRIGGERS_SIZE: 64,
      SIO_HW3_MAX_TRIGGERS_SIZE: 64,
      MMS_X5_HW1_MAX_TRIGGERS_SIZE: 64,
      LSC_HW1_MAX_TRIGGERS_SIZE: 32,
      LSC_V4_HW1_MAX_TRIGGERS_SIZE: 32,

      MMS_HW1_MAX_TRIGGERS_ACTIVATIONS_SIZE: 12,
      MMS_HW3_MAX_TRIGGERS_ACTIVATIONS_SIZE: 24,
      SIO_HW3_MAX_TRIGGERS_ACTIVATIONS_SIZE: 24,
      MMS_X5_HW1_MAX_TRIGGERS_ACTIVATIONS_SIZE: 24,
      LSC_HW1_MAX_TRIGGERS_ACTIVATIONS_SIZE: 5,
      LSC_V4_HW1_MAX_TRIGGERS_ACTIVATIONS_SIZE: 5
    },
    signal_quality: {
      good: "GOOD",
      fair: "FAIR",
      bad: "BAD",
    }
  }
};
