import React from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { TerminalDeviceSensorGenericView } from "./TerminalDeviceSensorGenericView";

export const TerminalDeviceI2CSensorView = (props) => {

  const {
    terminalDevice,
    onI2CSensorClick
  } = props

  return <TerminalDeviceSensorGenericView 
    terminalDevice={terminalDevice} 
    physicalCommunicationType={projectsConstants.global.sensors.phys.I2C}
    onSensorClick={onI2CSensorClick}
    />
};