import React, { useCallback, useState, useEffect } from "react";
import { MaintenanceMQTT } from "../../MQTT/MaintenanceMQTT";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { ReactComponent as DangerIcoOffOutputs } from "./../../../../images/ImagesTerminalsDevice/danger.svg";
import "react-toggle/style.css";
import { PrincipalCardView } from "./Principal_CardView/Principal_CardView";

//Fichas Tarjeta
import "./TerminalDevices.sass";
import { OutputService } from "../../../../_services/outputcontroller.service";
import _ from "lodash";
import { MaintenanceButton } from "./Components/MaintenanceButton/MaintenanceButton";
import { TerminalDeviceConfiguration } from "./Components/TerminalDeviceConfiguration";
//import Popup from "reactjs-popup";
import { HelpView } from "./HelpView/HelpView";
import { useOutputsAPI } from "../../../../context/outputs-context";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../../context/app-context";
import { TSMComponent } from "../TerminalDevicesFather/TSM/TSMComponent";
import { TerminalDeviceOutputStatesHandler } from "../OutputsView/TerminalDeviceOutputStatesHandler";
import { TerminalDeviceSensorStatesHandler } from "../TerminalDeviceSensorStatesHandler";
import {
  TerminalDeviceNavbar,
  TerminalDeviceNavbarStates,
} from "../TerminalDeviceNavbar";
import { TerminalDeviceSensorsStats } from "../SensorsView/TerminalDeviceSensorsStats";
import { useTerminalDevice } from "../../../../context/terminal-device-context";
import { AtomizerComponent } from "../TerminalDevicesFather/Atomizer/AtomizerComponent";
const {
  output_active: OUTPUT_ACTIVE,
  output_activated: OUTPUT_ACTIVATED,
  output_deactivated: OUTPUT_DEACTIVATED,
  output_transition: OUTPUT_TRANSITION,
} = projectsConstants.global.states;

const fromStartToStop = 0;
const fromStopToStart = 1;

export const TerminalDevices = (props) => {
  const { selectedZone, selectedTerminal } = useApp();
  const history = useNavigate();
  const SIO = projectsConstants.global.devices.SIO;
  const MMS = projectsConstants.global.devices.MMS;
  const PKT7 = projectsConstants.global.devices.PKT7;
  const LORA = projectsConstants.global.devices.LORA;
  const LORA_V2 = projectsConstants.global.devices.LORA_V2;
  const TL01_TL03 = projectsConstants.global.devices.TL01_TL03;
  const TSM = projectsConstants.global.devices.TSM;
  const ACK = projectsConstants.global.codes.ACK;
  const MMS_X5 = projectsConstants.global.devices.MMS_X5;

  const setinMaintenanceErrorResponse =
    "El dispositivo no ha podido entrar/salir de modo mantenimiento.";

  const {
    updateTerminalDevice,
    reloadTerminalDevice,
    terminalDevices,
  } = useApp();

  const { terminalDevice } = useTerminalDevice();

  const {
    lastStatus,
    device: Device,
  } = terminalDevice;

  // Estados mantenimiento!
  const [stopButtonEnabled, setstopButtonEnabled] = useState(true);
  const [isInMaintenanceError, setisInMaintenanceError] = useState(false);
  const [InMaintenanceError] = useState(setinMaintenanceErrorResponse);

  const [inMaintenanceTransition, setinMaintenanceTransition] = useState(
    terminalDevice.isInMaintenance() ? fromStopToStart : fromStartToStop
  );

  const {
    actoutput,
    endoutput,
    maintenance,
    advanced_actoutput,
    isValidForAdvancedActoutput,
  } = useOutputsAPI();

  const initState = {
    navBarState: TerminalDeviceNavbarStates.HOME,
  };
  const [state, setState] = useState(initState);

  const setPutConfigureOutputs = useCallback(
    (terminalDeviceId, data) => {
      return OutputService.putTerminalDeviceOutputConfiguration(
        terminalDevice?.terminal,
        terminalDeviceId,
        data
      );
    },
    [terminalDevice]
  );

  useEffect(() => {
    if (state.navBarState === TerminalDeviceNavbarStates.PROGRAMS) {
      history({
        pathname: `/zones/${selectedZone.id}/sector/${selectedTerminal.sector}/terminals/${terminalDevice.terminal}/terminalDevices/${terminalDevice.id}/programs`,
        dataprops: { ...props },
      });
    }
  }, [
    history,
    props,
    selectedTerminal?.sector,
    selectedZone?.id,
    state?.navBarState,
    terminalDevice?.id,
    terminalDevice?.terminal,
  ]);

  const updateOutputs = useCallback(
    (outputs) => {
      if (
        outputs instanceof Array &&
        terminalDevice?.outputs instanceof Array
      ) {
        let tmpTerminalDevice = _.cloneDeep(terminalDevice);
        outputs.forEach((output) => {
          if (
            tmpTerminalDevice.outputs.filter(
              (sourceOutput) => sourceOutput?.output === output?.output
            ).length > 0
          ) {
            tmpTerminalDevice.outputs = tmpTerminalDevice.outputs.map(
              (sourceOutput) =>
                sourceOutput?.output === output?.output ? output : sourceOutput
            );
          } else {
            tmpTerminalDevice.outputs.push(output);
          }
        });
        updateTerminalDevice(tmpTerminalDevice);
      } else {
        // Consultamos la API.
        reloadTerminalDevice(terminalDevice.id);
      }
    },
    [
      terminalDevice,
      updateTerminalDevice,
      reloadTerminalDevice,
    ]
  );

  /********** Datos sensores desde sensorsfather-->**********/

  const goToProgram = useCallback(
    (program) => {
      //Llama a programas:
      if (
        selectedTerminal?.sector !== undefined &&
        selectedZone?.id !== undefined &&
        terminalDevice?.id !== undefined &&
        terminalDevice?.terminal !== undefined
      )
        history({
          pathname: `/zones/${selectedZone.id}/sector/${selectedTerminal.sector}/terminals/${terminalDevice.terminal}/terminalDevices/${terminalDevice.id}/programs/${program.id}`,
          dataprops: { ...props },
          programid: program.id,
          sectorid: selectedTerminal.sector,
          terminaldeviceid: program.terminalDeviceId,
          zoneid: selectedZone.id,
        });
    },
    [
      history,
      props,
      selectedTerminal?.sector,
      selectedZone?.id,
      terminalDevice?.id,
      terminalDevice?.terminal,
    ]
  );

  /*************** END SENSORS *********/
  const OffOuputAlls = useCallback(() => {
    terminalDevice
      .outputs
      .forEach((element) => {
        if (
          element.currentState === OUTPUT_ACTIVATED ||
          element.currentState === OUTPUT_ACTIVE
        ) {
          element.prevState = element.currentState;
          element.currentState = OUTPUT_TRANSITION;
        }
      });

    updateTerminalDevice(terminalDevice)
    endoutput(terminalDevice.id, projectsConstants.master_outputs.all_outputs);
  }, [endoutput, terminalDevice, updateTerminalDevice]);

  const OnOuputAlls = useCallback(() => {

    if (isValidForAdvancedActoutput(terminalDevice)) {
      advanced_actoutput(terminalDevice.id, undefined, 0xffff);
      terminalDevice
      .outputs
      .forEach((output) => {
        if (output.currentState === OUTPUT_DEACTIVATED) {
          output.prevState = output.currentState;
          output.currentState = OUTPUT_TRANSITION;
        }
      });
    } else {
      terminalDevice
      .outputs
      .forEach((output) => {
        if (output.currentState === OUTPUT_DEACTIVATED) {
          output.prevState = output.currentState;
          output.currentState = OUTPUT_TRANSITION;

          actoutput(
            terminalDevice.id,
            output.output,
            output.getActivationType(),
            output.activationParams
          );
        }
      });
    }

    updateTerminalDevice(terminalDevice)
  }, [isValidForAdvancedActoutput, terminalDevice, advanced_actoutput, actoutput, updateTerminalDevice]);

  const ActionStopClick = useCallback(() => {
    //Confirmación de que vas a poner la tarjeta en modo mantenimiento
    setstopButtonEnabled(false);
    setisInMaintenanceError(false);
    setinMaintenanceTransition(fromStopToStart);
    terminalDevice.setMaintenanceExpected(true);
    maintenance(terminalDevice.id, terminalDevice.isMaintenanceExpected());
  }, [terminalDevice, maintenance]);

  const ActionStartClick = useCallback(() => {
    setstopButtonEnabled(false);
    setisInMaintenanceError(false);
    setinMaintenanceTransition(fromStartToStop);
    terminalDevice.setMaintenanceExpected(false);
    maintenance(terminalDevice.id, terminalDevice.isMaintenanceExpected());
  }, [maintenance, terminalDevice]);

  /********** MQTT MESSAGES *************/
  const processMaintenanceMsg = useCallback(
    (messageId, message) => {
      const data = message?.data;
      const terminalDeviceMaintenance = data.maintenance?.maintenance;
      const success = data.success === ACK;
      const target_id = data.maintenance?.target_id;
      if (
        terminalDeviceMaintenance !== undefined &&
        target_id === terminalDevice.id
      ) {
        if (success) {
          terminalDevice.setInMaintenance(terminalDeviceMaintenance);
          if (terminalDeviceMaintenance) {
            // Implica que se han apagado todas las salidas.
            terminalDevice
            .outputs
            .forEach((element) => {
              element.currentState = OUTPUT_DEACTIVATED;
            });
            updateTerminalDevice(terminalDevice);
          }
        } else {
          setisInMaintenanceError(true);
        }
        setstopButtonEnabled(true);
      }
    },
    [ACK, terminalDevice, updateTerminalDevice]
  );
  const processMaintenanceErrorMsg = useCallback(
    (messageId, message) => {
      const target_id = message?.data?.target_id;
      if (target_id === terminalDevice.id) {
        setstopButtonEnabled(true);
      }
    },
    [terminalDevice]
  );

  const UpdateViewName = useCallback((name) => {
    terminalDevice.description = name;
    updateTerminalDevice(terminalDevice);
  }, [terminalDevice, updateTerminalDevice]);
  /****** END MQTT MESSAGES *************/

  const onNavbarOptionSelected = useCallback((option) => {
    setState((prev) => {
      return { ...prev, navBarState: option };
    });
  }, []);

  const onClickBack = useCallback(() => {
    setState((prev) => {
      return { ...prev, navBarState: TerminalDeviceNavbarStates.HOME };
    });
  }, []);

  const getHomeNavbar = useCallback(() => {
    return (
      <TerminalDeviceNavbar
        showConfig={true}
        showStats={true}
        showPrograms={Device?.id !== PKT7}
        showInfo={true}
        infoComponent={<HelpView />}
        onOptionClick={onNavbarOptionSelected}
      ></TerminalDeviceNavbar>
    );
  }, [Device?.id, PKT7, onNavbarOptionSelected]);

  const getConfigNavbar = useCallback(() => {
    return (
      <TerminalDeviceNavbar
        showHome={true}
        showStats={true}
        showPrograms={Device?.id !== PKT7}
        showInfo={true}
        infoComponent={<HelpView />}
        onOptionClick={onNavbarOptionSelected}
      ></TerminalDeviceNavbar>
    );
  }, [Device?.id, PKT7, onNavbarOptionSelected]);

  const getStatsNavbar = useCallback(() => {
    return (
      <TerminalDeviceNavbar
        showHome={true}
        showPrograms={Device?.id !== PKT7}
        showInfo={true}
        infoComponent={<HelpView />}
        onOptionClick={onNavbarOptionSelected}
      ></TerminalDeviceNavbar>
    );
  }, [Device?.id, PKT7, onNavbarOptionSelected]);

  const getNavbar = useCallback(() => {
    switch (state.navBarState) {
      case TerminalDeviceNavbarStates.HOME:
        return getHomeNavbar();
      case TerminalDeviceNavbarStates.CONFIG:
        return getConfigNavbar();
      case TerminalDeviceNavbarStates.STATS:
        return getStatsNavbar();
      default:
        break;
    }
    return <></>;
  }, [getConfigNavbar, getHomeNavbar, getStatsNavbar, state.navBarState]);

  return (
    <>
      <TerminalDeviceOutputStatesHandler />
      {(Device?.id === SIO || Device?.id === MMS || Device?.id === PKT7 || Device?.id === MMS_X5) && (
        <>
  
          <TerminalDeviceSensorStatesHandler terminalDevice={terminalDevice} />
          <div className="Terminals">
            <div className="TitleTerminaldevices">
              <div className="TitleTd">{terminalDevice?.description || Device?.name}</div>

              <MaintenanceButton
                terminalDevice={terminalDevice}
                stopButtonEnabled={stopButtonEnabled}
                ActionStopClick={ActionStopClick}
                ActionStartClick={ActionStartClick}
                InMaintenanceTransition={inMaintenanceTransition}
                fromStartToStop={fromStartToStop}
              />
            </div>
            <MaintenanceMQTT
              processMsg={processMaintenanceMsg}
              processErrorMsg={processMaintenanceErrorMsg}
            />

            {/* Mensaje de Mantenimiento*/}
            {isInMaintenanceError && (
              <div className="childCardDevices">
                <div className="Title_Descripction">
                  <DangerIcoOffOutputs
                    className="PicPlugTD"
                    fill="red"
                    stroke="red"
                  />
                  {InMaintenanceError}
                </div>
              </div>
            )}
            {/* Menu de opciones E/S*/}
            {getNavbar()}
            <>
              {/* ---VISTAS----*/}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* ---Principal----*/}

                {state.navBarState === TerminalDeviceNavbarStates.HOME && (
                  <PrincipalCardView
                    terminalDevice={terminalDevice}
                    goToProgram={goToProgram}
                    OffOuputAlls={OffOuputAlls}
                    OnOuputAlls={OnOuputAlls}
                    DangerIcoOffOutputs={DangerIcoOffOutputs}
                  />
                )}
                {/* ---Opciones----*/}
                {state.navBarState === TerminalDeviceNavbarStates.CONFIG && (
                  <TerminalDeviceConfiguration
                    type={true}
                    terminalDevice={terminalDevice}
                    outputs={terminalDevice.outputs}
                    sendData={setPutConfigureOutputs}
                    updateOutputs={updateOutputs}
                    laststatusCut={lastStatus}
                    goBack={onClickBack}
                    UpdateViewName={UpdateViewName}
                    {...props}
                  />
                )}

                {state.navBarState === TerminalDeviceNavbarStates.STATS && (
                  <TerminalDeviceSensorsStats
                    terminalDevice={terminalDevice}
                  ></TerminalDeviceSensorsStats>
                )}
              </div>
            </>
          </div>
        </>
      )}

      {Device?.id === TSM && (
        <>
          <TSMComponent
            terminalDevice={terminalDevice}
            terminalDevices={terminalDevices}
            {...props}
          />
        </>
      )}

      {(Device?.id === LORA ||
        Device?.id === LORA_V2 ||
        Device?.id === TL01_TL03 || 
        Device?.id === projectsConstants.global.devices.LSC_V1 ||
        Device?.id == projectsConstants.global.devices.LSC_V4) && <AtomizerComponent onTriggerClick={goToProgram}/>}
    </>
  );
};
