import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { terminalService } from "../../../../../_services/terminal.service";
import { Outputconfiguration } from "../../Fichas/OutputConfiguration/Outputconfiguration";

import "./terminalDeviceConfiguration.sass";
import { DangerComponent } from "../../../../DangerComponent/DangerComponent";
import { CheckComponent } from "../../../Airframe/CheckComponent/CheckComponents";
import { Spinner } from "reactstrap";
import Check from "./check.png";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { AccordionWithContent } from "../../../Airframe/AccordionDesplegator/AccordionWithContent";
import { SensorsConfiguration } from "../../Fichas/SensorsConfiguration/SensorsConfiguration";
import { TerminalDeviceFooterInfo } from "./TerminalDeviceFooterInfo";
import { DigitalSensorsConfiguration } from "../../Fichas/SensorsConfiguration/DigitalSensors/DigitalSensorsConfiguration";
import { isTerminalDeviceValidForDigitalSensorsTriggers, isTerminalDeviceValidForDatetimeTriggers } from "../../Fichas/Triggers/TerminalDeviceTriggerChecker";
import { TerminalDeviceTriggersConfig } from "../../Fichas/Triggers/TerminalDeviceTriggersConfig";
import { isSensorDisableAllowed, isSensorLiveCalibrationAllowed, isSensorSpecificReportTimeAllowed, shouldIgnoreSensorNotified } from "../../Fichas/SensorsConfiguration/SensorsConfigurationChecker";

const typesControlResponse = {
  Mqtt: "Mqtt",
  Api: "Api",
  NotSync: "NotSync",
};

export const TerminalDeviceConfiguration = (props) => {
  const { terminalDevice, UpdateViewName, goBack } = props;
  const [namedevice, setnamedevice] = useState(terminalDevice.device.name);
  const [boolModificatedSensor, setboolModificatedSensor] = useState(false);
  const [boolModificatedOutput, setboolModificatedOutput] = useState(false);
  const [boolModificatedType, setboolModificatedType] = useState(false);
  const [boolModificatedName, setboolModificatedName] = useState(false);
  const [boolModificatedCUD, setboolModificatedCUD] = useState(false);
  const [
    boolModificatedDigitalSensors,
    setboolModificatedDigitalSensors,
  ] = useState(false);
  const [flagModificatedSensor, setflagModificatedSensor] = useState(0);
  const [flagModificatedOutput, setflagModificatedOutput] = useState(0);
  const [flagModificatedAlarm, setflagModificatedAlarm] = useState(0);
  const [flagModificatedType, setflagModificatedType] = useState(0);
  const [flagModificatedCUD, setFlagModificatedCUD] = useState(0);
  const [
    flagModificatedDigitalSensor,
    setFlagModificatedDigitalSensor,
  ] = useState(0);
  const [waitingMqttResponse, setwaitingMqttResponse] = useState(false);
  const [waitingApiResponse, setwaitingApiResponse] = useState(false);
  const [boolModificatedAlarm, setboolModificatedAlarm] = useState(false);

  const errorsOnSaving = useRef({});
  const errorsOnNotifying = useRef({});
  const successOnNotifying = useRef({});

  const [dataOutputConf, setdataOutputConf] = useState({
    output: undefined,
    type: undefined,
    description: undefined,
    activation: undefined,
  });

  const [
    outputNotifyTerminalDeviceSpinner,
    setOutputNotifyTerminalDeviceSpinner,
  ] = useState(false);

  const typesAccordions = useMemo(
    () => ({
      Alarms: "Alarms",
      Outputs: "Outputs",
      Sensors: "Sensors",
      Name: "Name",
      CUDs: "CUDs",
      DigitalSensors: "DigitalSensors",
      Type: "Type",
    }),
    []
  );

  const buttonAction = useMemo(
    () => ({
      start: "start",
      finalized: "finalized",
      error: "error",
      deactivate: "deactivate",
    }),
    []
  );

  const [boolLoadingSavingServer, setboolLoadingSavingServer] = useState({
    start: false,
    finalized: false,
    error: false,
  });

  const [messageSavingSensor, setmessageSavingSensor] = useState(undefined);
  const [messageSavingOutput, setmessageSavingOutput] = useState(undefined);
  const [messageSavingAlarm, setmessageSavingAlarm] = useState(undefined);
  const [messageSavingType, setmessageSavingType] = useState(undefined);

  //Ref focus:
  /****************** ELEMENT CONFIGURATION *****************/

  useEffect(() => {
    if (terminalDevice?.description !== null) {
      setnamedevice(terminalDevice.description);
    }
  }, [terminalDevice]);

  const controlWaitingResponse = useCallback(
    (value, type) => {
      if (type === typesControlResponse.Mqtt) {
        setwaitingMqttResponse(value);
        setOutputNotifyTerminalDeviceSpinner(value);
      } else if (type === typesControlResponse.Api) {
        setwaitingApiResponse(value);
      } else if (type === typesControlResponse.NotSync) {
        setOutputNotifyTerminalDeviceSpinner(value);
      }
    },
    [
      typesControlResponse.Api,
      typesControlResponse.Mqtt,
      typesControlResponse.NotSync,
    ]
  );

  const showButtonSave = useCallback(
    (type) => {
      switch (type) {
        case typesAccordions.Outputs:
          setboolModificatedOutput(true);
          break;
        case typesAccordions.Sensors:
          setboolModificatedSensor(true);
          break;
        case typesAccordions.Name:
          setboolModificatedName(true);
          break;
        case typesAccordions.Type:
          setboolModificatedType(true);
          break;
        case typesAccordions.Alarms:
          setboolModificatedAlarm(true);
          break;
        case typesAccordions.CUDs:
          setboolModificatedCUD(true);
          break;
        case typesAccordions.DigitalSensors:
          setboolModificatedDigitalSensors(true);
          break;
        default:
          break;
      }
    },
    [
      typesAccordions.Outputs,
      typesAccordions.Sensors,
      typesAccordions.Name,
      typesAccordions.Type,
      typesAccordions.Alarms,
      typesAccordions.CUDs,
      typesAccordions.DigitalSensors,
    ]
  );

  const changeSelectOutput = useCallback(
    (selectOutput) => {
      setdataOutputConf({
        ...dataOutputConf,
        output: selectOutput?.output,
        description: selectOutput?.description,
      });
    },
    [dataOutputConf]
  );

  const SaveName = useCallback(
    (name) => {
      setnamedevice(name);
      showButtonSave(typesAccordions.Name);
    },
    [showButtonSave, typesAccordions.Name]
  );

  //#region ERROR/SUCCESS SAVING 7 NOTIFYING
  const onErrorSaving = useCallback((type) => {
    errorsOnSaving.current = { ...errorsOnSaving.current, [type]: true };
  }, []);

  const onSuccessSaving = useCallback(
    (type) => {
      switch (type) {
        case typesAccordions.Outputs:
          setboolModificatedOutput(false);
          break;
        case typesAccordions.Sensors:
          setboolModificatedSensor(false);
          break;
        case typesAccordions.Name:
          setboolModificatedName(false);
          break;
        case typesAccordions.Type:
          setboolModificatedType(false);
          break;
        case typesAccordions.Alarms:
          setboolModificatedAlarm(false);
          break;
        case typesAccordions.CUDs:
          setboolModificatedCUD(false);
          break;
        case typesAccordions.DigitalSensors:
          setboolModificatedDigitalSensors(false);
          break;
        default:
          break;
      }
    },
    [
      typesAccordions.Alarms,
      typesAccordions.CUDs,
      typesAccordions.DigitalSensors,
      typesAccordions.Name,
      typesAccordions.Outputs,
      typesAccordions.Sensors,
      typesAccordions.Type,
    ]
  );

  const onErrorNotifying = useCallback((type) => {
    errorsOnNotifying.current = { ...errorsOnNotifying.current, [type]: true };
  }, []);
  const onSuccessNotifying = useCallback((type) => {
    successOnNotifying.current = {
      ...successOnNotifying.current,
      [type]: true,
    };
  }, []);
  //#endregion

  const UpdateServerTerminalDeviceDescription = useCallback(
    (name) => {
      const varsend = {
        description: name,
        outputsVoltageExpected: terminalDevice.outputsVoltageExpected,
      };
      UpdateViewName(name);

      terminalService
        .updateTerminalDevice(
          terminalDevice.terminal,
          terminalDevice.id,
          varsend
        )
        .then((terminalchange) => {
          terminalDevice.description = terminalchange.description;
          onSuccessSaving(typesAccordions.Name);
        })
        .catch((error) => {
          onErrorSaving(typesAccordions.Name);
        });
    },
    [
      terminalDevice,
      UpdateViewName,
      onSuccessSaving,
      typesAccordions.Name,
      onErrorSaving,
    ]
  );

  const savechanges = useCallback(() => {
    errorsOnSaving.current = {};

    if (boolModificatedName) {
      UpdateServerTerminalDeviceDescription(namedevice);
    }

    if (boolModificatedOutput) {
      setflagModificatedOutput(flagModificatedOutput + 1);
    }

    if (boolModificatedSensor) {
      setflagModificatedSensor(flagModificatedSensor + 1);
    }
    if (boolModificatedType) {
      setflagModificatedType(flagModificatedSensor + 1);
    }
    if (boolModificatedAlarm) {
      setflagModificatedAlarm(flagModificatedAlarm + 1);
    }

    if (boolModificatedCUD) {
      setFlagModificatedCUD((prev) => prev + 1);
    }

    if (boolModificatedDigitalSensors) {
      setFlagModificatedDigitalSensor((prev) => prev + 1);
    }
  }, [
    boolModificatedName,
    boolModificatedOutput,
    boolModificatedSensor,
    boolModificatedType,
    boolModificatedAlarm,
    boolModificatedCUD,
    boolModificatedDigitalSensors,
    UpdateServerTerminalDeviceDescription,
    namedevice,
    flagModificatedOutput,
    flagModificatedSensor,
    flagModificatedAlarm,
  ]);

  const getNotificationComponent = useCallback(() => {
    const numberOfSuccess = Object.keys(successOnNotifying.current).length;
    const numberOfErrors = Object.keys(errorsOnNotifying.current).length;

    return (
      <>
        {successOnNotifying.current && numberOfSuccess > 0 && (
          <CheckComponent
            message={`Se han notificado ${
              numberOfErrors === 0 ? "todos los" : "algunos"
            } datos al TerminalDevice.`}
          />
        )}
        {errorsOnNotifying.current && numberOfErrors > 0 && (
          <DangerComponent
            message={"Ha habido un error notificando al TerminalDevice."}
          />
        )}
        {outputNotifyTerminalDeviceSpinner && (
          <div>Notificando al dispositivo...</div>
        )}
      </>
    );
  }, [outputNotifyTerminalDeviceSpinner]);

  const infoLoadingServer = useCallback(
    (buttonActionAction, type, datasave, crud) => {
      if (datasave) {
        switch (type) {
          case typesAccordions.Outputs:
            console.log(datasave);
            setmessageSavingOutput(
              <>
                <div>{"Salida: "}</div>
                <div>{datasave.output + "." + datasave.description}</div>
              </>
            );
            break;
          case typesAccordions.Sensors:
            console.log(datasave);
            let tmpInfoSensor = datasave.active;
            if (tmpInfoSensor) {
              tmpInfoSensor = "activado";
            } else {
              tmpInfoSensor = "desactivado";
            }
            setmessageSavingSensor(
              <>
                <div>
                  {"Sensor " + (datasave.sensorIndex + 1) + ": "}
                  {tmpInfoSensor}
                </div>
              </>
            );
            break;
          case typesAccordions.Alarms:
            console.log(datasave);
            let numbertmp = datasave.sensor;
            if (crud === "add") {
              setmessageSavingAlarm(
                <>
                  <div>Alarma creada</div>
                  <div>
                    {numbertmp} con valor: {datasave.value}
                  </div>
                </>
              );
            } else if (crud === "edit") {
              setmessageSavingAlarm(
                <>
                  <div>Alarma editada</div>
                  <div>
                    {numbertmp} con valor: {datasave.value}
                  </div>
                </>
              );
            } else if (crud === "delete") {
              setmessageSavingAlarm(
                <>
                  <div>Alarma borrada</div>
                  <div>
                    {numbertmp} con valor: {datasave.value}
                  </div>
                </>
              );
            } else {
              console.log("Error");
            }

            break;
          case typesAccordions.Type:
            if (crud === "add") {
              setmessageSavingType("Tipo creado: " + datasave.abbreviation);
            } else if (crud === "edit") {
              setmessageSavingType("Tipo editado: " + datasave.abbreviation);
            } else if (crud === "delete") {
              setmessageSavingType("Tipo borrado: " + datasave.abbreviation);
            } else {
              console.log("Error");
            }

            break;
          default:
            break;
        }
      }

      switch (buttonActionAction) {
        case buttonAction.start:
          setboolLoadingSavingServer({
            start: true,
            finalized: false,
            error: false,
          });
          break;
        case buttonAction.finalized:
          setboolLoadingSavingServer({
            start: false,
            finalized: true,
            error: false,
          });
          break;
        case buttonAction.error:
          setboolLoadingSavingServer({
            start: false,
            finalized: false,
            error: true,
          });
          break;
        case buttonAction.deactivate:
          setboolLoadingSavingServer({
            start: false,
            finalized: false,
            error: false,
          });

          setmessageSavingSensor(undefined);
          setmessageSavingOutput(undefined);
          setmessageSavingAlarm(undefined);
          setmessageSavingType(undefined);
          break;
        default:
          break;
      }
    },
    [
      buttonAction.deactivate,
      buttonAction.error,
      buttonAction.finalized,
      buttonAction.start,
      typesAccordions.Alarms,
      typesAccordions.Outputs,
      typesAccordions.Sensors,
      typesAccordions.Type,
    ]
  );

  const getOutputsAccordionContent = useCallback(() => {
    return (
      <div>
        <Outputconfiguration
          {...props}
          typesControlResponse={typesControlResponse}
          controlWaitingResponse={controlWaitingResponse}
          waitingMqttResponse={waitingMqttResponse}
          waitingApiResponse={waitingApiResponse}
          id={typesAccordions.Outputs}
          showButtonSave={showButtonSave}
          mustSave={flagModificatedOutput}
          onErrorSaving={onErrorSaving}
          onSuccessSaving={onSuccessSaving}
          onErrorNotifying={onErrorNotifying}
          onSuccessNotifying={onSuccessNotifying}
          changeSelectOutput={changeSelectOutput}
          dataOutputConf={dataOutputConf}
          infoLoadingServer={infoLoadingServer}
          type={typesAccordions.Outputs}
          buttonAction={buttonAction}
          disableOutputType={true}
        />
      </div>
    );
  }, [controlWaitingResponse, waitingMqttResponse, waitingApiResponse, typesAccordions.Outputs, showButtonSave, flagModificatedOutput, onErrorSaving, onSuccessSaving, onErrorNotifying, onSuccessNotifying, changeSelectOutput, dataOutputConf, infoLoadingServer, buttonAction, props]);

  return (
    <div className="confiterminal">
      {/*successSaving && <CheckComponent message={""}></CheckComponent>*/}
      {getNotificationComponent()}
      <div className="NameDevice">
        <div className="Title_Descripction Bold center margintop">
          Nombre del dispositivo:
        </div>

        <input
          type="text"
          id="Namedevice"
          name="Namedevice"
          placeholder={namedevice}
          className={`InputForm descriptionInput form-control ${
            (errorsOnSaving.current[typesAccordions.Name] ||
              errorsOnNotifying.current[typesAccordions.Name]) &&
            "is-invalid"
          }`}
          onChange={(e) => SaveName(e.target.value)}
        />
      </div>

      <AccordionWithContent title={<>Salidas</>}>
        {getOutputsAccordionContent()}
      </AccordionWithContent>
      <AccordionWithContent title={<>Sensores</>}>
        <SensorsConfiguration
          {...props}
          id={typesAccordions.Sensors}
          showButtonSave={showButtonSave}
          mustSave={flagModificatedSensor}
          onErrorSaving={onErrorSaving}
          onSuccessSaving={onSuccessSaving}
          infoLoadingServer={infoLoadingServer}
          buttonAction={buttonAction}
        />
      </AccordionWithContent>
      <AccordionWithContent title={<>Caudalímetros</>}>
        <DigitalSensorsConfiguration
          terminalDevice={terminalDevice}
          physicalCommunicationType={projectsConstants.global.sensors.phys.cuds}
          onDigitalSensorChangeNotification={showButtonSave}
          changeNotificationType={typesAccordions.CUDs}
          mustSave={flagModificatedCUD}
          onErrorSaving={onErrorSaving}
          onSuccessSaving={onSuccessSaving}
          allowSpecificReportTime={isSensorSpecificReportTimeAllowed(terminalDevice)}
          allowDisable={isSensorDisableAllowed(terminalDevice)}
          ignoreNotified={shouldIgnoreSensorNotified(terminalDevice)}
        ></DigitalSensorsConfiguration>
      </AccordionWithContent>
      {isTerminalDeviceValidForDigitalSensorsTriggers(terminalDevice) && (
        <AccordionWithContent title={<>Pulsos</>}>
          <DigitalSensorsConfiguration
            terminalDevice={terminalDevice}
            physicalCommunicationType={projectsConstants.global.sensors.phys.digital}
            onDigitalSensorChangeNotification={showButtonSave}
            changeNotificationType={typesAccordions.DigitalSensors}
            mustSave={flagModificatedDigitalSensor}
            onErrorSaving={onErrorSaving}
            onSuccessSaving={onSuccessSaving}
          ></DigitalSensorsConfiguration>
        </AccordionWithContent>
      )}
      {isTerminalDeviceValidForDatetimeTriggers(terminalDevice) && (
        <AccordionWithContent title={<>Programas</>}>
          <TerminalDeviceTriggersConfig />
        </AccordionWithContent>
      )}

      {waitingApiResponse || waitingMqttResponse ? (
        <></>
      ) : (
        <>
          <div className="heightButtonConf">
            {!boolModificatedSensor &&
              !boolModificatedName &&
              !boolModificatedOutput &&
              !boolModificatedType &&
              !boolModificatedAlarm &&
              !boolModificatedCUD &&
              !boolModificatedDigitalSensors && (
                <div className="Buttons">
                  <button className="ButtonOffAll " onClick={goBack}>
                    Atras
                  </button>
                  <div>{boolLoadingSavingServer.error && <>error</>}</div>
                </div>
              )}

            {(messageSavingSensor ||
              messageSavingOutput ||
              messageSavingAlarm ||
              messageSavingType) && (
              <div className="messagesSavesConf">
                <div className="messageSavesTitleConf">Cambios:</div>
                <div className="messagesSavesConfcolumn">
                  <div className="messagesSavesConfc">
                    <div>{messageSavingSensor}</div>
                    <div>{messageSavingOutput}</div>
                    <div>{messageSavingAlarm}</div>
                    <div>{messageSavingType}</div>
                  </div>
                  <div>
                    {boolLoadingSavingServer.start && (
                      <>
                        <Spinner size={"25px"} />
                      </>
                    )}
                    {boolLoadingSavingServer.finalized && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                          className="Title_Descripction"
                        >
                          <div style={{ width: "15%" }}>
                            <img
                              src={Check}
                              alt="check"
                              className="PicPlugTD"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {(boolModificatedSensor ||
              boolModificatedName ||
              boolModificatedOutput ||
              boolModificatedType ||
              boolModificatedAlarm ||
              boolModificatedCUD ||
              boolModificatedDigitalSensors) && (
              <div className="Buttons">
                <button
                  className="ButtonOffAll "
                  //disabled={waitingApiResponse || waitingMqttResponse}
                  onClick={goBack}
                >
                  Atras
                </button>
                <button className="ButtonOffAll " onClick={savechanges}>
                  Guardar
                </button>
              </div>
            )}
            <TerminalDeviceFooterInfo terminalDevice={terminalDevice} />
          </div>
        </>
      )}
    </div>
  );
};
