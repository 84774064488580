import React from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { TerminalDeviceSensorGenericView } from "./TerminalDeviceSensorGenericView";

export const TerminalDeviceGenericAnalogSensorView = (props) => {

  const {
    terminalDevice,
    onAnalogSensorClick
  } = props

  return <TerminalDeviceSensorGenericView 
    terminalDevice={terminalDevice} 
    physicalCommunicationType={projectsConstants.global.sensors.phys.analog}
    onSensorClick={onAnalogSensorClick}
    />
};
