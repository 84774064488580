import React, { useCallback, useEffect } from "react";
import { projectsConstants } from "../../../../../../_constants/projects.constants";
import { DropdownC } from "../../../../Airframe/Dropdown/Dropdown";

export const TerminalDeviceSensorsDropDown = (props) => {
  const {
    sensors,
    maxAnalogInputs,
    disabled,
    onSelected,
    selectedSensor,
    physicalCommunicationTypes = [projectsConstants.global.sensors.phys.analog],
    filterOnlyActiveAndConfigured = false,
    useSensorTypeDescpritionIfEmtpyCustomDescription = true,
    isClearable = false
  } = props;

  const getSensorSuffix = useCallback(currentSensor => {
    if(currentSensor?.sensorId?.physicalCommunicationType === projectsConstants.global.sensors.phys.cuds){
      return "Caud";
    }

    return "Pulsos";
  }, [])

  const getTerminalDeviceSensorsDropDown = useCallback(() => {

    let dropDownOptions = [];
    let selectedOption = undefined;
    const maxSensors = filterOnlyActiveAndConfigured ? sensors?.length : maxAnalogInputs
    
    if(filterOnlyActiveAndConfigured){
      dropDownOptions = sensors
        .sort((a, b) => a?.sensorId?.physicalCommunicationType?.localeCompare(b?.sensorId?.physicalCommunicationType))
        .filter(currentSensor => physicalCommunicationTypes.includes(currentSensor?.sensorId?.physicalCommunicationType))
        .map(currentSensor => {
          const label = currentSensor.description || (useSensorTypeDescpritionIfEmtpyCustomDescription ? currentSensor.sensorId.description : "")
          return {
            id: currentSensor.sensorIndex + 1,
            value: `${currentSensor.sensorIndex + 1}: ${currentSensor.sensorId.name}`,
            label: `${getSensorSuffix(currentSensor)} ${currentSensor.sensorIndex+ 1}: ${label}`,
            sensor: currentSensor
          };
        })

      selectedOption = dropDownOptions.find(currentOption => {
        return currentOption?.sensor?.sensorIndex === selectedSensor?.sensorIndex 
          && currentOption?.sensor?.sensorId?.physicalCommunicationType === selectedSensor?.sensorId?.physicalCommunicationType
      })
    }
    else{
      for (let i = 0; i < maxSensors; i++) {
        let sensorConfigured = false;
  
        let sensorOption = undefined;
  
        for (let y = 0; y < sensors.length; y++) {
          const currentSensor = sensors[y];
          if (
            physicalCommunicationTypes.includes(currentSensor.sensorId.physicalCommunicationType) 
               && currentSensor.sensorIndex === i
          ) {
            sensorConfigured = true;
            const label = currentSensor.description || (useSensorTypeDescpritionIfEmtpyCustomDescription ? currentSensor.sensorId.description : "")
            sensorOption = {
              id: i + 1,
              value: `Sensor ${i + 1}: ${currentSensor.sensorId.name}`,
              label: `Sensor ${i + 1}: ${label}`,
              sensor: currentSensor
            };
            if (i === selectedSensor?.sensorIndex) {
         
              selectedOption = sensorOption;
            }
            break;
          }
        }
        
        const id = i + 1;
        if (sensorConfigured) {
          dropDownOptions.push(sensorOption);
        } else {
          const option = {
            id,
            value: `Sensor ${id}: NO CONFIGURADO`,
            label: `Sensor ${id}: NO CONFIGURADO`,
          };
          if (i === selectedSensor?.sensorIndex) {
            selectedOption = option;
          }
          dropDownOptions.push(option);
        }
      }
    }

    
   
    return (
      <>      
      <DropdownC
        options={dropDownOptions}
        onChange={(selected) => onSelected(selected?.id - 1, selected?.sensor)}
        placeholder={selectedOption || "Seleccione un sensor..."}
        disabled={disabled}
        value={selectedOption || null}
        isClearable={isClearable}
      />
      </>
    );
  }, [filterOnlyActiveAndConfigured, sensors, maxAnalogInputs, disabled, isClearable, physicalCommunicationTypes, useSensorTypeDescpritionIfEmtpyCustomDescription, selectedSensor?.sensorIndex, selectedSensor?.sensorId?.physicalCommunicationType, onSelected]);

  useEffect(() => {
  }, [props]);

  return <>{getTerminalDeviceSensorsDropDown()}</>;
};
