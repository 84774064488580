import { useSubscription } from "mqtt-react-hooks";
import React, { useEffect, useMemo, useState } from "react";
import { projectsConstants } from "../_constants/projects.constants";
import { useUser } from "./user-context";

const SubscriberContext = React.createContext();

export const SubscriberProvider = (props) => {
  const { user } = useUser();
  //const { mqttTopicMessageRecv } = useMqttState()
  const { message: mqttTopicMessageRecv} = useSubscription(`${process.env.REACT_APP_TECO_MQTT_CLOUD_ID}/${user.username}/+/notification/#`)
  const [actoutputMsg, setactoutputMsg] = useState(undefined);
  const [advancedActoutputMsg, setAdvancedActoutputMsg] = useState(undefined)
  const [endoutputMsg, setendoutputMsg] = useState(undefined);
  const [updatestateMsg, setupdatestateMsg] = useState(undefined);
  const [updateoutputsMsg, setupdateoutputsMsg] = useState(undefined);
  const [getonlineterminalsMsg, setgetonlineterminalsMsg] = useState(undefined);
  const [setworkmodeMsg, setsetworkmodeMsg] = useState(undefined);
  const [maintenanceMsg, setmaintenanceMsg] = useState(undefined);
  const [setoutputvMsg, setsetoutputvMsg] = useState(undefined);
  const [getsensorvalueMsg, setgetsensorvalueMsg] = useState(undefined);
  const [settasksbyidMsg, setsettasksbyidMsg] = useState(undefined);
  const [deletetasksMsg, setdeletetasksMsg] = useState(undefined);
  const [specConfig, setSpecConfig] = useState(undefined);
  const [specCalibration, setSpecCalibration] = useState(undefined);

  // COMMON
  const [setTriggersMsg, setSetTriggerMsg] = useState(undefined);
  const [deleteTriggersMsg, setDeleteTriggersMsg] = useState(undefined);
  const [reportSensorsMsg, setReportSensorsMsg] = useState(undefined);
  const [sensorsConfigMsg, setSensorsConfigMsg] = useState(undefined);
  const [joinedReportMsg, setJoinedReportMsg] = useState(undefined);

  // TSM
  const [tsmGetSensorValueMsg, setTsmGetSensorValueMsg] = useState(undefined);
  const [tsmGetSensorStatusMsg, setTsmGetSensorStatusMsg] = useState(undefined);
  const [tsmUpdatestatessMsg, setTsmUpdateStatesMsg] = useState(undefined);
  const [tsmCalibrateSensorMsg, setTsmCalibrateSensorMsg] = useState(undefined);
  const [tsmGetStatesMsg, setTsmGetStatesMsg] = useState(undefined);
  const [tsmCalibrationReportMsg, setTsmCalibrationReportMsg] = useState(undefined);

  // LoRa
  const [loraActoutputMsg, setLoraActoutputMsg] = useState(undefined);
  const [loraEndoutputMsg, setLoraEndoutputMsg] = useState(undefined);
  const [loraSettriggersMsg, setLoraSettriggersMsg] = useState(undefined);
  

  const getTopic = (action, project) => {
    let projectId = projectsConstants.master_outputs.id;
    switch(project){
      case projectsConstants.postigel.id:
        projectId = projectsConstants.postigel.id;
        break;
      case projectsConstants.lora_outputs.id:
        projectId = projectsConstants.lora_outputs.id
        break;
      default:
        break;
    }
    return `${process.env.REACT_APP_TECO_MQTT_CLOUD_ID}/${user.username}/${projectId}/notification/${action}`;
  };

  useEffect(() => {
    if (
      mqttTopicMessageRecv !== undefined &&
      user !== undefined &&
      mqttTopicMessageRecv.topic !== undefined &&
      mqttTopicMessageRecv.message !== undefined
    ) {
      const message = mqttTopicMessageRecv;
      console.log(message);
      if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.actoutput)
      ) {
        setactoutputMsg(message);
       }else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.advanced_actoutput)
      ) {
        setAdvancedActoutputMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.endoutput)
      ) {
        setendoutputMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.updatesstate)
      ) {
        setupdatestateMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.updateoutputs)
      ) {
        setupdateoutputsMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.getonlineterminals)
      ) {
        setgetonlineterminalsMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.setworkmode)
      ) {
        setsetworkmodeMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.maintenance)
      ) {
        setmaintenanceMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.setoutputv)
      ) {
        setsetoutputvMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.getsensorvalue)
      ) {
        setgetsensorvalueMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.settasksbyid)
      ) {
        setsettasksbyidMsg(message);
      } else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.deletetasks)
      ) {
        setdeletetasksMsg(message);
      }

      // COMMON
      else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.common.settriggers)
      ) {
        setSetTriggerMsg(message);
      }else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.common.deletetriggers)
      ) {
        setDeleteTriggersMsg(message);
      }else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.common.reportsensors)
      ) {
          setReportSensorsMsg(message)
      }
      else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.common.sensorsconfig)
      ) {
          setSensorsConfigMsg(message)
      }
      else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.common.joined_report)
      ) {
          setJoinedReportMsg(message)
      }

      // TSM dentro de master_outputs

      else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.tsm.tsm_getsensorvalue)
      ) {
        setTsmGetSensorValueMsg(message)
      }else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.tsm.tsm_getsensorstatus)
      ) {
        setTsmGetSensorStatusMsg(message)
      }else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.tsm.tsm_updatestates)
      ) {
        setTsmUpdateStatesMsg(message)
      }else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.tsm.tsm_calibratesensor)
      ) {
        setTsmCalibrateSensorMsg(message)
      }else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.tsm.tsm_getstates)
      ) {
        setTsmGetStatesMsg(message);
      }else if (mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.master_outputs.actions.tsm.tsm_calibrationreport)
      ) {
        setTsmCalibrationReportMsg(message);
      }

      // POSTIGEL
      else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.postigel.actions.specconfig, projectsConstants.postigel.id)
      ) {
        setSpecConfig(message);
      }else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.postigel.actions.speccalibration, projectsConstants.postigel.id)
      ) {
        setSpecCalibration(message);
      }

      // LoRa
      else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.lora_outputs.actions.actoutput, projectsConstants.lora_outputs.id)
      ) {
        setLoraActoutputMsg(message);
      }else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.lora_outputs.actions.endoutput, projectsConstants.lora_outputs.id)
      ) {
        setLoraEndoutputMsg(message);
      }else if (
        mqttTopicMessageRecv.topic ===
        getTopic(projectsConstants.lora_outputs.actions.settriggers, projectsConstants.lora_outputs.id)
      ) {
        setLoraSettriggersMsg(message);
      }
    }

    return () => { };
  }, [mqttTopicMessageRecv]);

  const value = useMemo(() => {
    return {
      actoutputMsg,
      advancedActoutputMsg,
      endoutputMsg,
      updatestateMsg,
      updateoutputsMsg,
      getonlineterminalsMsg,
      setworkmodeMsg,
      maintenanceMsg,
      setoutputvMsg,
      getsensorvalueMsg,
      settasksbyidMsg,
      deletetasksMsg,

      // COMMON
      setTriggersMsg,
      deleteTriggersMsg,
      reportSensorsMsg,
      sensorsConfigMsg,
      joinedReportMsg,

      // TSM
      tsmGetSensorValueMsg,
      tsmGetSensorStatusMsg,
      tsmUpdatestatessMsg,
      tsmCalibrateSensorMsg,
      tsmGetStatesMsg,
      tsmCalibrationReportMsg,

      // POSTIGEL
      specConfig,
      specCalibration,

      // LoRa
      loraActoutputMsg,
      loraEndoutputMsg,
      loraSettriggersMsg
    };
  }, [
    actoutputMsg,
    advancedActoutputMsg,
    endoutputMsg,
    updatestateMsg,
    updateoutputsMsg,
    getonlineterminalsMsg,
    setworkmodeMsg,
    maintenanceMsg,
    setoutputvMsg,
    getsensorvalueMsg,
    settasksbyidMsg,
    deletetasksMsg,

    setTriggersMsg,
    deleteTriggersMsg,
    reportSensorsMsg,
    sensorsConfigMsg,
    joinedReportMsg,
    
    tsmGetSensorValueMsg,
    tsmGetSensorStatusMsg,
    tsmUpdatestatessMsg,
    tsmCalibrateSensorMsg,
    tsmGetStatesMsg,
    tsmCalibrationReportMsg,

    specConfig,
    specCalibration,
    
    loraActoutputMsg,
    loraEndoutputMsg,
    loraSettriggersMsg
  ]);

  return <SubscriberContext.Provider value={value} {...props} />;
};

export const useSubscriber = () => {
  const context = React.useContext(SubscriberContext);
  if (!context) {
    throw new Error(
      "useSubscriber debe estar dentro del proveedore SubscriberContext"
    );
  }
  return context;
};

export default SubscriberContext;
