import React, { useEffect, useState } from "react";
import { getDayOfYearFormatted } from "../../../../_helpers/DateFormatHelper";
import { outputRegistersType, OutputService } from "../../../../_services/outputcontroller.service";
import { CardSensor } from "../SensorsView/CardSensor/CardSensor";

export const TerminalDeviceOutputCard = (props) => {
  const { terminalDevice, output } = props;

  const states = {
    IDLE: "IDLE",
    LOADING: "LOADING",
    LOADED: "LOADED",

    ERROR: "ERROR",
  };
  const initState = {
    state: states.IDLE,
    register: undefined,
  };

  const [state, setState] = useState(initState);

  useEffect(() => {
    if (state.state === states.IDLE) {
      setState({ ...state, state: states.LOADING });
      const actualday = getDayOfYearFormatted(new Date(Date.now()));
      // API
      OutputService.getTerminalDeviceOutputRegistersByPeriod(
        terminalDevice?.terminal,
        terminalDevice?.id,
        output?.output,
        actualday,
        undefined,
        outputRegistersType.DAY_ACCUMULATED
      ).then(
        (registers) => {
          let register = undefined;
          if (registers instanceof Array && registers.length > 0) {
            register = registers[registers.length - 1];
          }
          setState({ ...state, state: states.LOADED, register });
        },
        (error) => {
          setState({ ...state, state: states.ERROR });
        }
      );
    }
  }, [
    output,
    state,
    states.ERROR,
    states.IDLE,
    states.LOADED,
    states.LOADING,
    terminalDevice?.id,
    terminalDevice?.terminal,
  ]);

  return (
    <CardSensor
      key={output.output}
      id={output.output}
      sensorIndex={output.output - 1}
      sensorValue={state.register?.measures[0]?.accumulateds[0]?.value || 0}
      sensorUnit={state.register?.measures[0]?.unit || ""}
      loading={state.state === states.LOADING}
      alarm={0}
      dateTime={
        state.register?.receivedAt && new Date(state.register.receivedAt)
      }
      onClick={() => setState({...state, register: undefined, state: states.IDLE})}
      labelPrefix={"Salida"}
    />
  );
};
