import React, { useCallback, useState, useEffect } from "react";
import { useApp } from "../../../../../context/app-context";
import { useTerminalDevice } from "../../../../../context/terminal-device-context";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { TerminalDeviceOutputsCardsView } from "../../OutputsView/TerminalDeviceOutputsCardsView";
import { TerminalDeviceOutputsView } from "../../OutputsView/TerminalDeviceOutputsView";
import { TerminalDeviceGenericAnalogSensorView } from "../../SensorsView/TerminalDeviceGenericAnalogSensorView";
import {
  TerminalDeviceNavbar,
  TerminalDeviceNavbarStates,
} from "../../TerminalDeviceNavbar";
import { AtomizerDosificationConfiguration } from "./AtomizerDosificationConfiguration";
import { AtomizerSyncStatus } from "./AtomizerSyncStatus";
import { TerminalDeviceSensorsStats } from "../../SensorsView/TerminalDeviceSensorsStats";
import { useOutputsAPI } from "../../../../../context/outputs-context";
import { isTerminalDeviceValidForDatetimeTriggers, isTerminalDeviceValidForTriggers } from "../../Fichas/Triggers/TerminalDeviceTriggerChecker";
import { useNavigate } from "react-router";
import { DatetimeTriggersSummaryView } from "../../DatetimeTriggers/DatetimeTriggersSummaryView";
import { TerminalDeviceBatteryAndSolarSensorView } from "../../SensorsView/TerminalDeviceBatteryAndSolarSensorView";
import { TerminalDeviceGenericDigitalSensorView } from "../../SensorsView/TerminalDeviceGenericDigitalSensorView";
import { TerminalDeviceConfiguration } from "../../TerminalDevices/Components/TerminalDeviceConfiguration";
import _ from "lodash";
import { TerminalDeviceRS485SensorView } from "../../SensorsView/TerminalDeviceRS485SensorView";
import { TerminalDeviceI2CSensorView } from "../../SensorsView/TerminalDeviceI2CSensorView";

export const AtomizerComponent = (props) => {
  const { selectedZone, selectedTerminal, updateTerminalDevice, reloadTerminalDevice } = useApp();
  const { terminalDevice } = useTerminalDevice();
  const history = useNavigate()
  const { lora_outputs_actouput, lora_outputs_endoutput } = useOutputsAPI()
  const {onTriggerClick} = props

  const initState = {
    navBarOptionSelected: TerminalDeviceNavbarStates.HOME,
  };
  const [state, setState] = useState(initState);

  const onNavBarOptionClick = useCallback((option) => {

    if(option === TerminalDeviceNavbarStates.PROGRAMS){
      history({
        pathname: `/zones/${selectedZone.id}/sector/${selectedTerminal.sector}/terminals/${terminalDevice.terminal}/terminalDevices/${terminalDevice.id}/programs`,
        dataprops: { ...props, projectId: projectsConstants.lora_outputs.id },
      });
    }

    setState((currentState) => ({
      ...currentState,
      navBarOptionSelected: option,
    }));
  }, []);

  const onOutputClick = useCallback(
    (terminalDeviceId, outputId, outputIndex) => {
      if (
        terminalDevice?.id === terminalDeviceId &&
        terminalDevice?.outputs instanceof Array
      ) {
        const objectOutput = terminalDevice.outputs.find(
          (output) => output?.output === outputId
        );
        if (objectOutput) {

          if (objectOutput.currentState === projectsConstants.global.states.output_deactivated) {
            lora_outputs_actouput(terminalDeviceId, outputId)
          }
          else{
            lora_outputs_endoutput(terminalDeviceId, outputId)
          }

          objectOutput.currentState =
            projectsConstants.global.states.output_transition;

         
          updateTerminalDevice(terminalDevice);
        }
      }
    },
    [terminalDevice, updateTerminalDevice, lora_outputs_actouput, lora_outputs_endoutput]
  );

  const onOutputDisabledClick = useCallback(() => {}, []);

  const onTerminalDeviceDescriptionChange = useCallback(description =>  {
    terminalDevice.description = description;
    updateTerminalDevice(terminalDevice);
  }, [terminalDevice, updateTerminalDevice])

  const onConfigClickBack = useCallback(() => {
    setState((currentState) => ({
      ...currentState,
      navBarOptionSelected: TerminalDeviceNavbarStates.HOME,
    }));
  }, [])

  const onChangeOutputConfig = useCallback(outputConfig => {
    console.log(outputConfig)
  }, [])

  const updateOutputs = useCallback(
      (outputs) => {
        if (
          outputs instanceof Array &&
          terminalDevice?.outputs instanceof Array
        ) {
          let tmpTerminalDevice = _.cloneDeep(terminalDevice);
          outputs.forEach((output) => {
            if (
              tmpTerminalDevice.outputs.filter(
                (sourceOutput) => sourceOutput?.output === output?.output
              ).length > 0
            ) {
              tmpTerminalDevice.outputs = tmpTerminalDevice.outputs.map(
                (sourceOutput) =>
                  sourceOutput?.output === output?.output ? output : sourceOutput
              );
            } else {
              tmpTerminalDevice.outputs.push(output);
            }
          });
          updateTerminalDevice(tmpTerminalDevice);
        } else {
          // Consultamos la API.
          reloadTerminalDevice(terminalDevice.id);
        }
      },
      [
        terminalDevice,
        updateTerminalDevice,
        reloadTerminalDevice,
      ]
    );

  return (
    <div className="Terminals" key={terminalDevice?.id}>
      <div className="TitleTerminaldevices">
        <div className="TitleTd">
          {terminalDevice?.description || terminalDevice?.device?.name || ""}
        </div>
      </div>

      <div className="ContentCard">
        <TerminalDeviceNavbar
          showConfig={
            state.navBarOptionSelected !== TerminalDeviceNavbarStates.CONFIG
          }
          showHome={
            state.navBarOptionSelected !== TerminalDeviceNavbarStates.HOME
          }
          showStats={true}
          showPrograms={isTerminalDeviceValidForTriggers(terminalDevice)}
          showInfo={true}
          infoComponent={<></>}
          onOptionClick={onNavBarOptionClick}
        ></TerminalDeviceNavbar>

        <div className="NotificationTerminalDevices">
          <AtomizerSyncStatus />
          {state.navBarOptionSelected === TerminalDeviceNavbarStates.HOME && (
            <>
              <TerminalDeviceRS485SensorView 
                terminalDevice={terminalDevice}
                onI2CSensorClick={() => {}}
              />
              <TerminalDeviceI2CSensorView 
                terminalDevice={terminalDevice}
                onRS485SensorClick={() => {}}
              />
              <TerminalDeviceGenericAnalogSensorView
                terminalDevice={terminalDevice}
                onAnalogSensorClick={() => {}}
              />
              <TerminalDeviceGenericDigitalSensorView 
                terminalDevice={terminalDevice}
                onSensorClick={() => {}}
              />
              <TerminalDeviceBatteryAndSolarSensorView terminalDevice={terminalDevice} />
              <hr className="Barra" />
              <TerminalDeviceOutputsCardsView terminalDevice={terminalDevice} />
              {isTerminalDeviceValidForDatetimeTriggers(terminalDevice) && 
              <DatetimeTriggersSummaryView terminalDevice={terminalDevice} onTriggerClick={onTriggerClick}/>}

              <hr className="Barra" />
              <div className="subtitleapp">Salidas</div>
              <TerminalDeviceOutputsView
                mode={"principalview"}
                onClickOnDisabled={onOutputDisabledClick}
                onClick={onOutputClick}
                outputs={terminalDevice?.outputs || []}
                mustUseLongPress={true}
              />
            </>
          )}
          {state.navBarOptionSelected === TerminalDeviceNavbarStates.CONFIG && (
            <>
              {/*<AtomizerDosificationConfiguration />*/}
              <TerminalDeviceConfiguration
                type={true}
                terminalDevice={terminalDevice}
                outputs={terminalDevice.outputs}
                sendData={onChangeOutputConfig}
                updateOutputs={updateOutputs}
                goBack={onConfigClickBack}
                UpdateViewName={onTerminalDeviceDescriptionChange}
                {...props}
              />
            </>
          )}
          {state.navBarOptionSelected === TerminalDeviceNavbarStates.STATS && (
            <TerminalDeviceSensorsStats
              terminalDevice={terminalDevice}
            ></TerminalDeviceSensorsStats>
          )}
        </div>
      </div>
    </div>
  );
};
